export const generateFormDataFields = <T>(data: T, omitKeys: string[] = []) => {
  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    if (
      data[key] !== undefined &&
      data[key] !== null &&
      !omitKeys.includes(key)
    ) {
      const value = key === 'file' ? (data[key] as File) : String(data[key]);

      formData.append(key, value);
    }
  });

  return formData;
};

export const generateFormDataFieldsForUpdateEntityForm = <T>(
  data: T[],
  omitKeys: string[] = [],
) => {
  const formData = new FormData();

  data.forEach((item: any, index) => {
    Object.keys(item).forEach((key) => {
      if (data[index][key] !== undefined && !omitKeys.includes(key)) {
        const formDataKey = `${key}[${index}]`;
        const value =
          key === 'file'
            ? (data[index][key] as File)
            : String(data[index][key]);

        formData.append(formDataKey, value);
      }
    });
  });

  return formData;
};

export const generateFullIdInMultiEntitiesForm = (
  id: string,
  index: number,
) => {
  return `${index}.${id}`;
};
