import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  ChartContainer,
  ChartContainerBody,
  ChartContainerHeader,
  ChartContainerToolbar,
  ChartContainerUtils,
} from 'src/components/Charts/ChartContainer';
import { useGetDataForCharts } from 'src/modules/utils/hooks/common/request';
import { useChartRequiredUtils } from 'src/components/Charts/common';
import { LineChart } from 'src/components/Charts/2d/LineChart';
import { fetchHRChangesTerminatedBySiteAndReason } from 'src/modules/utils/Api/hrChanges';
import {
  calculateTopNSites,
  generateSumByEntity,
  useActiveTopSitesOptions,
} from '../hrChanges.helpers';
import { SelectForChart } from 'src/components/SelectForChart';
import { OptionForChart } from 'src/components/SelectForChart/OptionForChart';
import { getGloballySelectedSites } from 'src/modules/selectors/site';

interface IHRChangesTerminatedBySiteAndReasonProps {
  siteId?: number;
}

export const HRChangesTerminatedBySiteAndReason = ({
  siteId,
}: IHRChangesTerminatedBySiteAndReasonProps) => {
  const { t } = useTranslation();

  const globallySelectedSiteIds = useSelector(getGloballySelectedSites);
  const siteIds = React.useMemo(
    () => (siteId ? [siteId] : globallySelectedSiteIds),
    [globallySelectedSiteIds, siteId],
  );

  const activeTopSitesOptions = useActiveTopSitesOptions();

  const {
    chartRef,
    chartHeight,
    exportChart,
    toggleFullScreen,
  } = useChartRequiredUtils();

  const [activeTopSites, setActiveTopSites] = React.useState<
    'top3' | 'top10' | 'all'
  >(activeTopSitesOptions[0].id);

  const { data, getData, isLoading } = useGetDataForCharts(
    fetchHRChangesTerminatedBySiteAndReason,
    {
      initialData: [],
    },
  );

  const sumByTerminationReasonArray = generateSumByEntity(
    data,
    'terminationReason',
    'total',
  );

  const categories = React.useMemo(() => {
    const categoriesSet = new Set(data.map((item) => item.site));

    return [...categoriesSet];
  }, [data]);

  const series = React.useMemo(() => {
    const dataObject = data.reduce<{
      [terminationReason: string]: { [site: string]: number | null };
    }>((acc, item) => {
      const sitesWithZeroValues = categories.reduce(
        (categoriesObj, category) => {
          categoriesObj[category] = 0;

          return categoriesObj;
        },
        {},
      );
      if (!acc[item.terminationReason]) {
        acc[item.terminationReason] = sitesWithZeroValues;
      }

      acc[item.terminationReason][item.site] = item.total;

      return acc;
    }, {});

    let entriesToUse = Object.entries(dataObject);
    if (activeTopSites === 'top3') {
      const top3Sites = calculateTopNSites(sumByTerminationReasonArray, 3);

      entriesToUse = entriesToUse.filter((entry) =>
        top3Sites.includes(entry[0]),
      );
    }
    if (activeTopSites === 'top10') {
      const top10Sites = calculateTopNSites(sumByTerminationReasonArray, 10);
      entriesToUse = entriesToUse.filter((entry) =>
        top10Sites.includes(entry[0]),
      );
    }

    return entriesToUse.map(
      ([terminationReason, terminationReasonDataObj]) => ({
        name: terminationReason,
        type: 'line' as const,
        data: Object.values(terminationReasonDataObj),
        marker: {
          symbol: 'circle',
          radius: 2,
        },
      }),
    );
  }, [activeTopSites, categories, data, sumByTerminationReasonArray]);

  React.useEffect(() => {
    getData({ siteId: { inq: siteIds } }, ['site asc']);
  }, [getData, siteIds]);

  return (
    <ChartContainer>
      <ChartContainerHeader
        title={t('dashboard.terminated_by_site_and_by_reason')}
      >
        <ChartContainerToolbar>
          <ChartContainerUtils
            onToggleFullScreen={toggleFullScreen}
            onExport={() => exportChart('terminated_by_site_and_by_reason')}
          />

          <SelectForChart
            value={activeTopSites}
            onChange={(_, value) => {
              if (value) {
                setActiveTopSites(value);
              }
            }}
          >
            {activeTopSitesOptions.map((option) => (
              <OptionForChart key={option.id} value={option.id}>
                {option.label}
              </OptionForChart>
            ))}
          </SelectForChart>
        </ChartContainerToolbar>
      </ChartContainerHeader>
      <ChartContainerBody isLoading={isLoading} showNoData={!data.length}>
        <LineChart
          fullscreenTitle={t('dashboard.terminated_by_site_and_by_reason')}
          ref={chartRef}
          height={chartHeight}
          categories={categories}
          series={series}
        />
      </ChartContainerBody>
    </ChartContainer>
  );
};
