import { SchemaValidateFunction } from 'ajv';
import { getISOWeeksInYear } from 'date-fns';
import { get } from 'lodash';

export const validateWeekNumberKeyword = function (
  ...param: Parameters<SchemaValidateFunction>
): boolean {
  const [schema, data, , dataCxt] = param;
  const parentData = get(dataCxt, ['parentData']);

  const weekNumber = data;
  const yearNumber = get(parentData, [schema.yearNumberFieldName]);

  // skip checking if year or week sare empty
  if (!yearNumber || !weekNumber) {
    return true;
  }

  const maxAmountOfWeeks = getISOWeeksInYear(new Date(yearNumber, 0, 31));

  return weekNumber <= maxAmountOfWeeks;
};
