import React from 'react';
import { Sheet } from '@mui/joy';
import { AnyObject } from 'src/modules/types';
import { useTranslation } from 'react-i18next';
import FormikTextField from 'src/components/Formik/FormikTextField';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { FormikButtonGroup } from 'src/components/Formik/FormikButtonGroup';
import { ButtonGroupsContainer } from 'src/components/_ui-kit/ButtonGroup';
import { FormFiledButtonsGroupItemContainer } from 'src/components/Form/FormFiledButtonsGroupItemContainer';
import { useEmployeeFormTourConfig } from 'src/config/tours/employees';
import FormikInputPhoneNumber from 'src/components/Formik/FormikInputPhoneNumber';
import FormikImageUpload from '../../../../../components/Formik/FormikImageUpload';

interface ICreateEmployeeOptionalFieldsProps {
  formik: AnyObject;
}

export const CreateEmployeeOptionalFields = ({
  formik,
}: ICreateEmployeeOptionalFieldsProps) => {
  const { t } = useTranslation();

  const employeeFormTourConfig = useEmployeeFormTourConfig();

  return (
    <Sheet sx={{ bgcolor: 'inherit' }}>
      <FormFieldContainer>
        <FormikImageUpload
          name="file"
          isImageFromPropsSourceOfTruth
          inputId="create_employee_attachment"
          file={formik.values.file}
          formik={formik}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <FormikTextField
          variant="outlined"
          fullWidth
          id="emergencyContact"
          label={t('employees.emergency_contact')}
          name="emergencyContact"
          formik={formik}
          placeholder={t('common.add_your_text_here')}
          data-tour={employeeFormTourConfig.tour.emergencyContact.target}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <FormikInputPhoneNumber
          id={'phonenumber'}
          name={'phonenumber'}
          label={t('employees.phone')}
          formik={formik}
        />
      </FormFieldContainer>
      <FormFieldContainer>
        <FormikTextField
          variant="outlined"
          fullWidth
          id="email"
          label={t('employees.email')}
          name="email"
          formik={formik}
          placeholder={t('common.add_your_text_here')}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <FormikTextField
          variant="outlined"
          fullWidth
          id="wmsLogin"
          label={t('employees.wms_login')}
          name="wmsLogin"
          formik={formik}
          placeholder={t('common.add_your_text_here')}
          data-tour={employeeFormTourConfig.tour.wmsLogin.target}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <FormikTextField
          variant="outlined"
          fullWidth
          id="taktId"
          label={t('employees.taktId')}
          name="taktId"
          formik={formik}
          errorMode="onFieldChange"
          placeholder={t('common.add_your_text_here')}
          data-tour={employeeFormTourConfig.tour.taktId.target}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <FormikTextField
          variant="outlined"
          fullWidth
          id="comments"
          label={t('employees.comments')}
          name="comments"
          formik={formik}
          placeholder={t('common.add_your_text_here')}
          data-tour={employeeFormTourConfig.tour.comments.target}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <ButtonGroupsContainer>
          <FormFiledButtonsGroupItemContainer>
            <FormikButtonGroup
              id="active"
              label={t('employees.active')}
              formik={formik}
              config={[
                { label: t('common.active'), value: true },
                { label: t('common.unactive'), value: false },
              ]}
            />
          </FormFiledButtonsGroupItemContainer>
          <FormFiledButtonsGroupItemContainer>
            <FormikButtonGroup
              id="wotc"
              label={t('employees.wotc')}
              formik={formik}
              config={[
                { label: t('common.yes'), value: true },
                { label: t('common.no'), value: false },
              ]}
            />
          </FormFiledButtonsGroupItemContainer>
          <FormFiledButtonsGroupItemContainer>
            <FormikButtonGroup
              id="canRehire"
              label={t('employees.can_rehire')}
              formik={formik}
              config={[
                { label: t('common.yes'), value: true },
                { label: t('common.no'), value: false },
              ]}
            />
          </FormFiledButtonsGroupItemContainer>
        </ButtonGroupsContainer>
      </FormFieldContainer>
    </Sheet>
  );
};
