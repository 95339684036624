import { JSONSchemaType } from 'ajv';
import { ICreateWeekBasedInvoice } from '../../types';
// don't make this shorter since builder might face with circular dependencies
import { i18n } from '../../utils/i18n';

export const createWeekBasedInvoiceScheme: JSONSchemaType<ICreateWeekBasedInvoice> = {
  type: 'object',
  required: ['siteId', 'weekNumber', 'yearNumber'],
  additionalProperties: false,
  errorMessage: {
    required: i18n.t('error.validation.required'),
  },
  properties: {
    siteId: {
      default: NaN,
      isNotEmpty: true,
      type: 'number',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    weekNumber: {
      default: NaN,
      isNotEmpty: true,
      type: 'number',
      minimum: 1,
      maximum: 53,
      validateWeekNumber: {
        yearNumberFieldName: 'yearNumber',
      },
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
        validateWeekNumber: `Week Number can't be bigger than max week number for entered year`,
      },
    },
    yearNumber: {
      default: NaN,
      isNotEmpty: true,
      type: 'number',
      minimum: 2015,
      maximum: 2100,
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
  },
};
