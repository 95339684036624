export const tabPrefix = '/tab';

export const paths = {
  ROOT: '/',

  APP_VERSION: '/app-version',

  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  FORGOT_PASSWORD_RESET_TOKEN: '/forgot-password/:resetToken',

  REGISTER: '/register',
  REGISTER_ACTIVATE_TOKEN: '/register/:activateToken',
  COMPLETE_PROFILE: '/complete-profile',
  COMPLETE_PROFILE_ACTIVATE_TOKEN: '/complete-profile/:activateToken',

  PRODUCTION_DASHBOARD: '/production-dashboard',

  LANDING: '/landing',
  PROFILE: '/profile',

  REPORTS_RECRUITING_DASHBOARD: '/reports/recruiting-dashboard',
  REPORTS_ISSUES_DASHBOARD: '/reports/issues-dashboard',
  REPORTS_DAILY_HOURS: '/reports/daily-hours',
  REPORTS_DAILY_HOURS_DASHBOARD: '/reports/daily-hours-dashboard',
  REPORTS_DAILY_HOURS_DASHBOARD_IN: '/reports/daily-hours-dashboard-in',
  REPORTS_WEEKLY_HOURS_DASHBOARD: '/reports/weekly-hours-dashboard',
  REPORTS_COSTS: '/reports/costs',
  REPORTS_COSTS_BY_DEPARTMENT_DASHBOARD:
    '/reports/costs-by-department-dashboard',
  REPORTS_COSTS_BY_DAY_DASHBOARD: '/reports/costs-by-day-dashboard',
  REPORTS_COSTS_BY_DAY_AND_DEPARTMENT_DASHBOARD:
    '/reports/costs-sum-by-day-and-department',
  REPORTS_TURNOVER_DASHBOARD: '/reports/turnover-dashboard',

  EXECUTIVE_REPORTS: '/executive/reports',
  EXECUTIVE_SUMMARY: '/executive/summary',

  FINANCIAL_CHARTS: '/financial-charts/:token',
  FINANCIAL_TRACKER: '/financial-tracker',
  FINANCIAL_TRACKER_REPORTS: '/financial-tracker/reports',
  FINANCIAL_TRACKER_DASHBOARD: '/financial-tracker/dashboard',

  POSITIONS: '/positions',

  AREA_LIST: '/area-list',

  CLIENTS: '/clients',

  SITES: '/sites',

  SHIFTS: '/shifts',
  // SHIFTS_CREATE: '/shifts/create',
  // SHIFTS_UPDATE: '/shifts/update',

  AUTOMATIC_POINTS_REJECTION_REASON: '/automatic-points-reject-reasons',

  EMPLOYEES: '/employees',
  // EMPLOYEES_CREATE: '/employees/create',
  // EMPLOYEES_UPDATE: '/employees/update',
  EMPLOYEES_POINTS: '/employees/points',
  EMPLOYEES_QUESTIONS: '/employees-questions',
  EMPLOYEES_CREATE_WITHOUT_PAYMENT_INFO:
    '/employees/create-without-payment-info',

  DASHBOARD: '/dashboard',

  EMPLOYEES_ACTIVE_BUT_NOT_PRESENT: '/employees/active-but-not-present',
  EMPLOYEES_TERMINATED: '/employees/terminated',
  HOOKY_FOLKS: '/employees/hooky-folks',
  HISTORICAL_HOOKY_FOLKS: '/employees/hooky-folks/historical',

  EMPLOYEES_FILL_IN_QUESTIONNAIRE: '/employees/fill-in-questionnaire',
  EMPLOYEES_FILL_IN_OVERDUE_QUESTIONNAIRE:
    '/employees/fill-in-overdue-questionnaire',

  DEPARTMENTS: '/departments',

  TIME_KEEPING_PUNCHES: '/logtimes',
  TIME_KEEPING_SUPERVISOR_VIEW: '/supervisor/view',
  TIME_KEEPING_PUNCHES_APPROVED_SHALLOW: '/time-keeping/approved',

  LOGTIMES: '/logtimes',
  // LOGTIMES_APPROVED: '/logtimes/approved',
  // LOGTIMES_UNAPPROVED: '/logtimes/unapproved',
  LOGTIMES_CREATE: '/logtimes/create',
  LOGTIMES_UPDATE: '/logtimes/update',

  STAFFING_PROVIDERS: '/staffing-providers',

  SKILLS: '/skills',

  ROLES: '/roles',

  TERMINATION_REASONS: '/termination-reasons',

  IP_WHITELIST: '/ip-whitelist',

  EMPLOYEE_SKILL_FLEX: '/employee-skill-flex',
  EMPLOYEE_SKILLS_CREATE: '/employee-skills/create',
  // EMPLOYEE_SKILLS_UPDATE: '/employee-skills/update',

  // WORKDAYS: '/workdays',
  // WORKDAYS_UPDATE: '/workdays/update',

  ISSUE_TYPES: '/issue-types',

  ISSUES: '/issues',
  ISSUES_CREATE: '/issues/create',
  ISSUES_UPDATE: '/issues/update',

  POINT_COLLECTIONS: '/point-collections',

  POINT_TYPES: '/point-types',

  // POINTS: '/points',
  POINTS_CREATE: '/points/create',
  POINTS_UPDATE: '/points/update',
  POINTS_REPORT_SUMMARY: '/points/report-summary',
  POINTS_REMOVE: '/points/remove',

  METATAGS: '/metatags',

  STANDARDS: '/standards',

  PRICING: '/pricing',

  FAVORITE: '/favorites',

  KPI: '/kpi',
  KPI_REPORT: '/kpi/report',
  KPI_TABLE: '/kpi/table',
  KPI_SETTINGS: '/kpi-settings',
  KPI_SETTING_RECORD: '/kpi-settings/record',

  INCENTIVE_PAYMENT_SETTINGS: '/incentive-payment/settings',
  INCENTIVE_PRICES: '/incentive-prices',

  BASELINE: '/baseline',
  BASELINE_CREATE: '/baseline/create',
  BASELINE_UPDATE: '/baseline/update',
  CPU_BASED_BASELINE: '/baseline/cpu-based',
  CPU_BASED_BASELINE_CREATE: '/baseline/cpu-based/create',
  CPU_BASED_BASELINE_UPDATE: '/baseline/cpu-based/update',
  AREA_BASED_BASELINE: '/baseline/area-based',
  AREA_BASED_BASELINE_CREATE: '/baseline/area-based/create',
  AREA_BASED_BASELINE_UPDATE: '/baseline/area-based/update',

  PAYBOARD: '/payboard',
  PAYBOARD_HISTORICAL: '/payboard-historical',
  INCENTIVE: '/incentive',
  PAYBOARD_DETAILED: '/payboard/detailed',
  PAYBOARD_HISTORICAL_DETAILED: '/payboard-historical/detailed',
  PAYBOARD_WEEK_VIEW: '/payboard/week-view',
  NEW_PAYBOARD_WEEK_VIEW: '/payboard/new-week-view',
  NEW_PAYBOARD_HISTORICAL_WEEK_VIEW: '/payboard-historical/new-week-view',
  PAYBOARD_DAILY_HOURS_SUMMARY_REPORT: '/payboard/daily_hours_summary',
  PAYBOARD_SUMMARY_BY_WORK_WEEK: '/payboard/daily-summary-by-work-week',
  PAYBOARD_SUMMARY_BY_WORK_WEEK_AND_SUPERVISOR:
    '/payboard/work-week-by-supervisor',
  PAYBOARD_COST_SUM_BY_DEPARTMENT: '/payboard/cost/sum-by-department',
  PAYBOARD_COST_SUM_BY_DAY: '/payboard/cost/sum-by-day',
  PAYBOARD_COST_SUM_BY_DAY_AND_DEPARTMENT:
    '/payboard/cost/sum-by-day-and-department',

  FILLED_ASSOCIATES: '/sites/filled-associates',

  CUSTOMER_REPORTS_COST_PLUS_WEEKLY: '/customer-reports/cost-plus-weekly',

  USERS: '/users',

  SUPERVISOR_VIEW: '/supervisor/view',
  TURNOVER: '/turnover',

  RECRUITING_DASHBOARD_SUMMARY: '/recruiting-dashboard/summary',
  RECRUITING_REPORT: '/sites/absenteeism',
  RECRUITING_DASHBOARD_CLOSED_POSITIONS:
    '/recruiting-dashboard/closed-positions',
  RECRUITING_DASHBOARD_CREATE_OPEN_POSITION:
    '/recruiting-dashboard/open-position/create',
  RECRUITING_DASHBOARD_UPDATE_OPEN_POSITION:
    '/recruiting-dashboard/open-position/:siteId',
  RECRUITING_DASHBOARD_OPEN_POSITION_VIEW:
    '/recruiting-dashboard/open-position/:siteId/details',
  RECRUITING_DASHBOARD_CREATE_APPLICANT:
    '/recruiting-dashboard/applicants/create',
  RECRUITING_DASHBOARD_UPDATE_APPLICANT:
    '/recruiting-dashboard/applicants/update',
  RECRUITING_DASHBOARD_LIST_APPLICANTS: '/recruiting-dashboard/applicants',
  RECRUITING_DASHBOARD_VIEW_APPLICANTS: '/recruiting-dashboard/applicants/view',
  RECRUITING_DASHBOARD_DETAILS: '/recruiting-dashboard/:siteId',

  POLICIES: '/policies',
  POLICIES_CREATE: '/policies/create',
  POLICIES_CREATE_BY_PAGE: '/policies/create/by-page',
  POLICIES_UPDATE: '/policies/update',

  OPTITURN_CREDENTIALS: '/optiturn-credentials',

  SITE_TAKT_EXPORTING_SETTINGS: '/site-exporting-settings/takt',

  INVOICE: '/invoice',
  INVOICE_SETTINGS: '/invoice/settings',
  INVOICE_CREATE: '/invoice/create',
  INVOICE_UPDATE: '/invoice/update',
  MONTH_INVOICE_UPDATE: '/invoice/month/update',
  INVOICE_DETAILS: '/invoice/details/:invoiceId',
  MONTH_INVOICE_DETAILS: '/invoice/month/details/:invoiceId',

  ADDITIONAL_FEE_UPDATE: '/additional-fee/update',

  PRODUCTIONS: '/productions',
  PRODUCTIONS_CREATE: '/productions/create',
  PRODUCTIONS_UPDATE: '/productions/update',

  PRODUCTION_SETTINGS: '/production-settings',

  IMPORT_UPLOAD_DATA: (clientKey = ':clientKey') =>
    `/${clientKey}/import/upload`,

  GUIDE: '/guide',

  TRAINING_VIDEOS: '/training-videos',

  CHECKLIST: '/checklist',

  //Gross Margin
  WEEKLY_GROSS_MARGIN: '/gross-margin/weekly',
  WEEKLY_GROSS_MARGIN_DETAILED: '/gross-margin',

  // Brookfield
  BROOKFIELD_REPORTS: '/brookfield/reports',

  // Brookfield Weekly
  BROOKFIELD_WEEKLY_STATIC_COST_PLUS: '/brookfield/weekly-cpu/static-cost-plus',

  // Cardinal-health
  CARDINAL_HEALTH_REPORTS: '/cardinal-health/reports',
  // Cardinal-Health Daily
  CARDINAL_HEALTH_DAILY_UPH_BY_SITE: '/cardinal-health/daily-uph/by-site',
  CARDINAL_HEALTH_DAILY_REPORT: '/cardinal-health/daily-report',
  CARDINAL_HEALTH_DAILY_UPH_BY_SITE_SKU:
    '/cardinal-health/daily-uph/by-site-and-sku',
  CARDINAL_HEALTH_DAILY_UPH_BY_SITE_EMPLOYEE:
    '/cardinal-health/daily-uph/by-site-and-employee',
  CARDINAL_HEALTH_DAILY_UPH_BY_SITE_SKU_AND_EMPLOYEE:
    '/cardinal-health/daily-uph/by-site-sku-and-employee',
  CARDINAL_HEALTH_DAILY_UPH_BY_SITE_AND_SHIFT:
    '/cardinal-health/daily-uph/by-site-and-shift',

  // Cardinal-Health Weekly
  CARDINAL_HEALTH_WEEKLY_UPH_BY_SITE: '/cardinal-health/weekly-uph/by-site',
  CARDINAL_HEALTH_WEEKLY_UPH_BY_SITE_AND_SHIFT:
    '/cardinal-health/weekly-uph/by-site-and-shift',
  CARDINAL_HEALTH_WEEKLY_UPH_BY_SITE_AND_SKU:
    '/cardinal-health/weekly-uph/by-site-and-sku',
  CARDINAL_HEALTH_WEEKLY_UPH_BY_SITE_AND_EMPLOYEE:
    '/cardinal-health/weekly-uph/by-site-and-employee',
  CARDINAL_HEALTH_WEEKLY_UPH_BY_SITE_SKU_AND_EMPLOYEE:
    '/cardinal-health/weekly-uph/by-site-sku-and-employee',
  CARDINAL_HEALTH_EXCEPTION_FROM_EMPLOYEES:
    '/cardinal-health/exceptions/production/from-employee',
  CARDINAL_HEALTH_EXCEPTION_FROM_PRODUCTION:
    '/cardinal-health/exceptions/production/from-production',

  // Cardinal-Health Weekly CPU
  CARDINAL_HEALTH_CPU: '/cardinal-health/cpu',
  CARDINAL_HEALTH_CPU_DETAILED: '/cardinal-health/cpu/detailed',

  // Dorman
  DORMAN_REPORTS: '/dorman/reports',

  // Levis
  LEVIS_REPORTS: '/levis/reports',

  // Dorman Weekly
  DORMAN_WEEKLY_STATIC_COST_PLUS: '/dorman/weekly-cpu/static-cost-plus',

  // Barrett
  BARRETT_REPORTS: '/barrett/reports',

  // Carparts
  CARPARTS_REPORTS: '/carparts/reports',

  // taylor_farms
  TAYLOR_FARMS_REPORTS: '/taylor-farms/reports',
  TAYLOR_FARMS_PAYBOARD_SUMMARY: '/taylor-farms/payboard-summary',
  TAYLOR_FARMS_KRONOS_PAYBOARD: '/taylor-farms/kronos-payboard',

  // Optoro
  OPTORO_REPORTS: '/optoro/reports',
  OPTORO_DAILY_REPORT: '/optoro/daily',
  OPTORO_DAILY_UPH_BY_SITE: '/optoro/daily-uph/by-site',
  OPTORO_DAILY_UPH_BY_SITE_AND_SHIFT: '/optoro/daily-uph/by-site-and-shift',
  OPTORO_DAILY_UPH_BY_SITE_AND_EMPLOYEE:
    '/optoro/daily-uph/by-site-and-employee',
  OPTORO_WEEKLY_UPH_BY_SITE: '/optoro/weekly-uph/by-site',
  OPTORO_WEEKLY_UPH_BY_SITE_AND_EMPLOYEE:
    '/optoro/weekly-uph/by-site-and-employee',
  OPTORO_WEEKLY_UPH_BY_SITE_AND_SHIFT: '/optoro/weekly-uph/by-site-and-shift',
  OPTORO_EXCEPTION_FROM_PRODUCTION:
    '/optoro/exceptions/production/from-production',
  OPTORO_EXCEPTION_FROM_EMPLOYEE: '/optoro/exceptions/production/from-employee',
  OPTORO_WEEKLY_INCENTIVE_INDIVIDUAL: '/optoro/incentive/individual',
  OPTORO_WEEKLY_INCENTIVE_INDIRECT: '/optoro/incentive/indirect',

  PULSE_SUMMARY: '/optiturn/pulse/summary',

  // Target
  TARGET_REPORTS: '/target/reports',
  TARGET_WEEKLY_CPU: '/target/weekly-cpu',
  TARGET_VIRTUAL_LOCATIONS_INVENTORY: '/target/virtual-locations-inventory',
  TARGET_DAILY_CPU: '/target/daily-cpu',
  TARGET_DAILY_PRODUCTION: '/target/daily-production',
  TARGET_WEEKLY_PRODUCTION: '/target/weekly-production',
  TARGET_TOP5_CPU: '/target/top5-cpu',
  TARGET_PULSE_SUMMARY: '/optiturn/pulse/summary',

  // NFI
  NFI_REPORTS: '/nfi/reports',
  NFI_WEEKLY_INCENTIVE_INDIVIDUAL: '/nfi/incentive/individual',
  NFI_WEEKLY_INCENTIVE_TEAM_LEADS: '/nfi/incentive/team-leads',

  // Sony
  SONY_REPORTS: '/sony/reports',
  SONY_WEEKLY_CPU: '/sony/weekly-cpu',
  SONY_INVENTORY_STORAGE_UTILIZATION: '/sony/inventory/storage-utilization',
  SONY_INVENTORY_STORAGE_UTILIZATION_TOTAL:
    '/sony/inventory/storage-utilization-total',

  // williams-sonoma
  WILLIAMS_SONOMA_REPORTS: '/williams-sonoma/reports',
  WILLIAMS_SONOMA_WEEKLY_CPU: '/williams-sonoma/weekly-cpu',
  WILLIAMS_SONOMA_DAILY_CPU: '/williams-sonoma/daily-cpu',

  // Expeditors
  EXPEDITORS_REPORTS: '/expeditors/reports',
  EXPEDITORS_DAILY_REPORT: '/expeditors/daily-report',
  EXPEDITORS_DAILY_UPH_BY_SITE: '/expeditors/daily-uph/by-site',
  EXPEDITORS_DAILY_UPH_BY_SITE_AND_SHIFT:
    '/expeditors/daily-uph/by-site-and-shift',
  EXPEDITORS_DAILY_UPH_BY_SITE_AND_DEPARTMENT:
    '/expeditors/daily-uph/by-site-and-department',
  EXPEDITORS_DAILY_UPH_BY_SITE_SHIFT_AND_DEPARTMENT:
    '/expeditors/daily-uph/by-site-shift-and-department',
  EXPEDITORS_DAILY_UPH_BY_SITE_AND_EMPLOYEE:
    '/expeditors/daily-uph/by-site-and-employee',
  EXPEDITORS_DAILY_UPH_BY_SITE_EMPLOYEE_AND_DEPARTMENT:
    '/expeditors/daily-uph/by-site-employee-and-department',
  EXPEDITORS_DAILY_UPH_BY_SITE_EMPLOYEE_SHIFT_AND_DEPARTMENT:
    '/expeditors/daily-uph/by-site-employee-shift-and-department',

  EXPEDITORS_EXCEPTION_FROM_EMPLOYEE:
    '/expeditors/exceptions/production/from-employee',
  EXPEDITORS_EXCEPTION_FROM_PRODUCTION:
    '/expeditors/exceptions/production/from-production',

  EXPEDITORS_WEEKLY_UPH_BY_SITE: '/expeditors/weekly-uph/by-site',
  EXPEDITORS_WEEKLY_UPH_BY_SITE_AND_SHIFT:
    '/expeditors/weekly-uph/by-site-and-shift',
  EXPEDITORS_WEEKLY_UPH_BY_SITE_AND_DEPARTMENT:
    '/expeditors/weekly-uph/by-site-and-department',
  EXPEDITORS_WEEKLY_UPH_BY_SITE_SHIFT_AND_DEPARTMENT:
    '/expeditors/weekly-uph/by-site-shift-and-department',
  EXPEDITORS_WEEKLY_UPH_BY_SITE_AND_EMPLOYEE:
    '/expeditors/weekly-uph/by-site-and-employee',
  EXPEDITORS_WEEKLY_UPH_BY_SITE_EMPLOYEE_AND_DEPARTMENT:
    '/expeditors/weekly-uph/by-site-employee-and-department',
  EXPEDITORS_WEEKLY_UPH_BY_SITE_EMPLOYEE_SHIFT_AND_DEPARTMENT:
    '/expeditors/weekly-uph/by-site-employee-shift-and-department',
  EXPEDITORS_GAPS_DAILY: '/expeditors/gaps/daily',

  // Perry Ellis
  PERRY_ELLIS_REPORTS: '/perry-ellis/reports',
  PERRY_ELLIS_DAILY_UPH_BY_AREA: '/perry-ellis/daily-uph/by-area',
  PERRY_ELLIS_DAILY_LPH_BY_AREA: '/perry-ellis/daily-lph/by-area',
  PERRY_ELLIS_WEEKLY_UPH_BY_AREA: '/perry-ellis/weekly-uph/by-area',
  PERRY_ELLIS_WEEKLY_LPH_BY_AREA: '/perry-ellis/weekly-lph/by-area',
  PERRY_ELLIS_GAPS_CHART: '/perry-ellis/gaps/chart',
  PERRY_ELLIS_GAPS_DAILY: '/perry-ellis/gaps/daily',
  PERRY_ELLIS_DAILY_REPORT: '/perry-ellis/daily-report',
  PERRY_ELLIS_GROSS_MARGIN: '/perry-ellis/gross-margin',

  // McEntire
  MCENTIRE_REPORTS: '/mc-entire/reports',
  MCENTIRE_DAILY_REPORT: '/mc-entire/daily-report',
  MCENTIRE_DAILY_UPH_BY_SITE: '/mc-entire/daily-uph/by-site',
  MCENTIRE_DAILY_UPH_BY_SITE_AND_SHIFT:
    '/mc-entire/daily-uph/by-site-and-shift',

  MCENTIRE_WEEKLY_UPH_BY_SITE: '/mc-entire/weekly-uph/by-site',
  MCENTIRE_WEEKLY_UPH_BY_SITE_AND_SHIFT:
    '/mc-entire/weekly-uph/by-site-and-shift',
  MCENTIRE_WEEKLY_CPU: '/mc-entire/weekly-cpu',

  // QUESTIONNAIRE_QUESTION: '/questionnaire/question',
  // QUESTIONNAIRE_QUESTION_CREATE: '/questionnaire/question/create',
  // QUESTIONNAIRE_QUESTION_UPDATE: '/questionnaire/question/update',
  // QUESTIONNAIRE_QUESTION_CATEGORY: '/questionnaire/question-category',
  // QUESTIONNAIRE_QUESTION_CATEGORY_CREATE:
  //   '/questionnaire/question-category/create',
  // QUESTIONNAIRE_QUESTION_CATEGORY_UPDATE:
  //   '/questionnaire/question-category/update',
  QUESTIONNAIRE_HISTORY: '/questionnaire/history',
  QUESTIONNAIRE_HISTORY_UPDATE: '/questionnaire/history/update',
  QUESTIONNAIRE_OVERDUE: '/questionnaire/overdue',

  ISSUE_TRACKER_LIST: '/issue-tracker',
  ISSUE_TRACKER_CREATE: '/issue-tracker/create',
  ISSUE_TRACKER_ISSUE_DETAILS: '/issue-tracker/details/:issueId',

  RESTORE: '/restore',

  PAGE_SETTING: '/page-settings',

  KNOWLEDGE_BASE_FAQ: `/knowledge/pages/public/faq`,
  KNOWLEDGE_BASE_DOCUMENTATION_PAGES: `/knowledge/documentation`,

  WIKI_SETUP_LIST: '/knowledge-setup/list',
  WIKI_SETUP_CREATE: '/knowledge-setup/create',
  WIKI_SETUP_UPDATE: '/knowledge-setup/update',
  WIKI_PAGE_BY_TYPE: (pageType = ':pageType') =>
    `/knowledge/pages/public/${pageType}`,
  WIKI_PAGE_BY_ID: (pageId = ':pageId') => `/knowledge-setup/pages/${pageId}`,
  WIKI_PAGE: (pageType = ':pageType', pageId = ':pageId') =>
    `/wiki-page/${pageType}/${pageId}`,

  SUPPORT_REQUEST:
    'https://app.smartsheet.com/b/form/2e3e427a708c419eb70028ac30aa2b90',

  CHANGELOG: '/changelog',
  CHANGELOG_HISTORY_EMPLOYEE: '/changelog/history/employee',
};

export const dynamicPathsWithRegExp = [
  {
    path: paths.RECRUITING_DASHBOARD_OPEN_POSITION_VIEW,
    regExp: /\/recruiting-dashboard\/open-position\/[0-9]+\/details/,
  },
  {
    path: paths.RECRUITING_DASHBOARD_UPDATE_OPEN_POSITION,
    regExp: /\/recruiting-dashboard\/open-position\/[0-9]+/,
  },
  {
    path: paths.RECRUITING_DASHBOARD_DETAILS,
    regExp: /\/recruiting-dashboard\/[0-9]+/,
  },
];

//! Pay attention that existence and placement of ${tabPrefix} is important here because it is used
// to decide render or not appropriate tab as well as first part fo the path: IT MUST BE MAIN PAGE
// PATH. Like for paths.TIME_KEEPING_PUNCHES main page path appropriate tabs MUST starts form this main path:
//  TIME_KEEPING_PUNCHES_APPROVED_TAB: `${paths.TIME_KEEPING_PUNCHES}${tabPrefix}/approved`,
//  TIME_KEEPING_PUNCHES_UNAPPROVED_TAB: `${paths.TIME_KEEPING_PUNCHES}${tabPrefix}/unapproved`,
export const tabsPaths = {
  EXECUTIVE_SUMMARY_EXECUTIVE_DASHBOARD_TAB: `${paths.EXECUTIVE_SUMMARY}${tabPrefix}/executive-dashboard`,
  EXECUTIVE_SUMMARY_FINANCIAL_DASHBOARD_TAB: `${paths.EXECUTIVE_SUMMARY}${tabPrefix}/financial-dashboard`,

  TIME_KEEPING_PUNCHES_APPROVED_TAB: `${paths.TIME_KEEPING_PUNCHES}${tabPrefix}/approved`,
  TIME_KEEPING_PUNCHES_UNAPPROVED_TAB: `${paths.TIME_KEEPING_PUNCHES}${tabPrefix}/unapproved`,
  TIME_KEEPING_PUNCHES_MISSED_TAB: `${paths.TIME_KEEPING_PUNCHES}${tabPrefix}/missed`,

  TIME_KEEPING_DAILY_HRS_TAB: `${paths.REPORTS_DAILY_HOURS}${tabPrefix}/daily-hours`,
  TIME_KEEPING_DAILY_HRS_DASHBOARD_TAB: `${paths.REPORTS_DAILY_HOURS}${tabPrefix}/dashboard`,
  TIME_KEEPING_DAILY_HRS_DASHBOARD_CHARTS_TAB: `${paths.REPORTS_DAILY_HOURS}${tabPrefix}/chart`,
  TIME_KEEPING_DAILY_HRS_DASHBOARD_IN_TAB: `${paths.REPORTS_DAILY_HOURS}${tabPrefix}/dashboard-in`,
  TIME_KEEPING_PAYBOARD_BY_WEEK_SUPERVISOR_TAB: `${paths.REPORTS_DAILY_HOURS}${tabPrefix}/by-week-supervisor`,
  TIME_KEEPING_INSERTED_PUNCHES_COUNT_TAB: `${paths.REPORTS_DAILY_HOURS}${tabPrefix}/inserted-punches-count`,

  EMPLOYEE_QUESTIONS_TAB: `${paths.EMPLOYEES_QUESTIONS}${tabPrefix}/questions`,
  EMPLOYEE_QUESTIONS_CATEGORIES_TAB: `${paths.EMPLOYEES_QUESTIONS}${tabPrefix}/questions-category`,
  EMPLOYEE_QUESTIONS_CATEGORIES_TYPES_TAB: `${paths.EMPLOYEES_QUESTIONS}${tabPrefix}/questions-category-types`,
  EMPLOYEES_REVIEWS: `${paths.QUESTIONNAIRE_HISTORY}${tabPrefix}/reviews`,
  EMPLOYEES_POINTS_REVIEWS: `${paths.QUESTIONNAIRE_HISTORY}${tabPrefix}/points-reviews`,
  EMPLOYEES_OVERDUE_REVIEWS: `${paths.QUESTIONNAIRE_HISTORY}${tabPrefix}/overdue-reviews`,

  EMPLOYEES_POINTS_LIST_TAB: `${paths.EMPLOYEES_POINTS}${tabPrefix}/list`,
  EMPLOYEES_POINTS_TOTAL_EARNED_TAB: `${paths.EMPLOYEES_POINTS}${tabPrefix}/total-earned`,
  EMPLOYEES_AUTOMATIC_POINTS_TO_APPROVE_TAB: `${paths.EMPLOYEES_POINTS}${tabPrefix}/automatic-points-to-approve`,

  DASHBOARD_FINANCIAL_REPORT_TAB: `${paths.DASHBOARD}${tabPrefix}/financial_dashboard`,
  DASHBOARD_HR_DASHBOARD_TAB: `${paths.DASHBOARD}${tabPrefix}/hr_dashboard`,
  DASHBOARD_HR_CHANGES_TAB: `${paths.DASHBOARD}${tabPrefix}/hr_changes`,
  DASHBOARD_RECRUITING_TAB: `${paths.DASHBOARD}${tabPrefix}/recruiting`,
  DASHBOARD_TURNOVER_BY_REASON_TAB: `${paths.DASHBOARD}${tabPrefix}/turnover-by-reason`,

  TIME_KEEPING_PAYBOARD_SUMMARY_TAB: `${paths.PAYBOARD}${tabPrefix}/summary`,
  TIME_KEEPING_PAYBOARD_DETAILED_TAB: `${paths.PAYBOARD}${tabPrefix}/detailed`,
  TIME_KEEPING_PAYBOARD_BY_WEEK_TAB: `${paths.PAYBOARD}${tabPrefix}/by-week`,
  TIME_KEEPING_PAYBOARD_BY_WEEK_DETAILED_TAB: `${paths.PAYBOARD}${tabPrefix}/by-week-detailed`,
  TIME_KEEPING_PAYBOARD_BY_DAY_TAB: `${paths.PAYBOARD}${tabPrefix}/by-day`,
  TIME_KEEPING_PAYBOARD_KRONOS_TAB: `${paths.PAYBOARD}${tabPrefix}/kronos`,
  TIME_KEEPING_PAYBOARD_SUMMARY_BY_WORK_WEEK_TAB: `${paths.PAYBOARD}${tabPrefix}/summary-by-work-week`,

  TIME_KEEPING_PAYBOARD_HISTORICAL_TAB: `${paths.PAYBOARD_HISTORICAL}${tabPrefix}/summary-historical`,
  TIME_KEEPING_PAYBOARD_DETAILED_HISTORICAL_TAB: `${paths.PAYBOARD_HISTORICAL}${tabPrefix}/detailed-historical`,
  TIME_KEEPING_PAYBOARD_KRONOS_HISTORICAL_TAB: `${paths.PAYBOARD_HISTORICAL}${tabPrefix}/kronos-historical`,

  TIME_KEEPING_INCENTIVE_INCENTIVE_PAYMENT_TAB: `${paths.INCENTIVE}${tabPrefix}/incentive-payment`,
  TIME_KEEPING_INCENTIVE_INCENTIVE_DETAILED_PAYMENT_TAB: `${paths.INCENTIVE}${tabPrefix}/incentive-detailed-payment`,

  ABSENTEE_REALTIME_TAB: `${paths.HOOKY_FOLKS}${tabPrefix}`,
  ABSENTEE_HISTORICAL_TAB: `${paths.HISTORICAL_HOOKY_FOLKS}${tabPrefix}`,

  // Costs reports
  COSTS_BY_DEPARTMENT: `${paths.REPORTS_COSTS}${tabPrefix}/by-department`,
  COSTS_BY_DAY: `${paths.REPORTS_COSTS}${tabPrefix}/by-day`,
  COSTS_BY_DAY_AND_DEPARTMENT: `${paths.REPORTS_COSTS}${tabPrefix}/by-day-and-department`,

  // Checklist Tabs
  TASKS_TAB: `${paths.CHECKLIST}${tabPrefix}/tasks`,
  CHECKLISTS_TAB: `${paths.CHECKLIST}${tabPrefix}/checklists`,

  // Turnover tabs
  TURNOVER_DASHBOARD_TAB: `${paths.REPORTS_TURNOVER_DASHBOARD}${tabPrefix}/dashboard`,
  TURNOVER_BY_REASON_TAB: `${paths.REPORTS_TURNOVER_DASHBOARD}${tabPrefix}/by-reason`,

  // Invoice
  INVOICE_WEEKLY_TAB: `${paths.INVOICE}${tabPrefix}/weekly`,
  INVOICE_MONTHLY_TAB: `${paths.INVOICE}${tabPrefix}/monthly`,

  // Invoice settings
  INVOICE_SETTINGS_HOURLY_SETTINGS: `${paths.INVOICE_SETTINGS}${tabPrefix}/hourly-settings`,
  INVOICE_SETTINGS_ADDITIONAL_FEE: `${paths.INVOICE_SETTINGS}${tabPrefix}/additional-fee`,
  INVOICE_SETTINGS_ADDITIONAL_FEE_CATEGORY: `${paths.INVOICE_SETTINGS}${tabPrefix}/additional-fee-category`,
  INVOICE_SETTINGS_SITE_BILLING_DETAILS: `${paths.INVOICE_SETTINGS}${tabPrefix}/site-billing-details`,
  INVOICE_SETTINGS_NGROUP_BILLING_DETAILS: `${paths.INVOICE_SETTINGS}${tabPrefix}/ngroup-billing-details`,
  INVOICE_SETTINGS_OTHER_COSTS: `${paths.INVOICE_SETTINGS}${tabPrefix}/other-costs`,

  // Site
  SITES_TAB: `${paths.SITES}${tabPrefix}/list`,
  ARCHIVED_SITES_TAB: `${paths.SITES}${tabPrefix}/archived`,
  IMPORTING_SETTINGS_TAB: `${paths.SITES}${tabPrefix}/importing-settings`,

  // Pricing
  PRICING_TAB: `${paths.PRICING}/pricing`,
  PRICING_BASELINE_TAB: `${paths.PRICING}/baseline`,
  PRICING_INCENTIVE_TAB: `${paths.PRICING}/incentive`,

  // Shifts
  SHIFT_TAB: `${paths.SHIFTS}/shift-list`,
  SHIFT_WORKDAYS_TAB: `${paths.SHIFTS}/workdays`,

  // Production Settings
  PRODUCTION_SETTINGS_TAB: `${paths.PRODUCTION_SETTINGS}${tabPrefix}/main`,
  PRODUCTION_SETTINGS_BOMS_TAB: `${paths.PRODUCTION_SETTINGS}${tabPrefix}/boms`,
  /**
   * Fake tabs (below) were invented in order to have ability to set up policies
   * for the details page without tabs
   * but under 'main menu page', which have tabs itself
   *
   * otherwise when you add {pageType: 'withTabs'} to the page config item
   * in the menuItems config - you will lose access to details page
   *
   * also it is related to 'isMenuItemWithTab' variable in PrivateRoute component
   */
  INVOICE_DETAILS_FAKE_TAB: `${paths.INVOICE_DETAILS}${tabPrefix}`,
  MONTH_INVOICE_DETAILS_FAKE_TAB: `${paths.MONTH_INVOICE_DETAILS}${tabPrefix}`,
} as const;

export const externalUrls = {
  guide: 'https://v953w.app.goo.gl/andUsJPT2MjwV8CT8',
};
