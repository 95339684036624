import { SagaIterator } from 'redux-saga';

import { call, put } from 'redux-saga/effects';
import { Api } from '../../utils';
import * as actions from '../../actions';
import {
  ICountResponse,
  ICreateEmployee,
  IdsArray,
  IEmployeeModel,
  IEmployeesActiveButNotPresentModel,
  IEmployeesTerminatedModel,
  IFilter,
  IHistoricalHookyFolksModel,
  IHookyFolksModel,
  ISagaAction,
  ISagaActionBind,
  IUpdateEmployee,
  PayloadWithFilters,
} from '../../types';
import { ExportSagaCreator } from '../../utils/helpers/creators/export';

/**
 * Create a new employee
 */
export const createEmployeeRequestSaga = function* ({
  payload: { data, filters },
}: ISagaAction<PayloadWithFilters<ICreateEmployee>>): SagaIterator {
  try {
    const response: IEmployeeModel = yield call(Api.Employee.create, data);

    yield put(actions.createEmployeeSuccess(response));

    yield put(actions.getEmployeeListRequest({ filter: filters.list }));
    yield put(actions.getEmployeeCountRequest({ filter: filters.count }));
    yield put(
      actions.addProcessStatus({
        variant: 'success',
        title: 'employees.employee_was_successfully_added',
        message:
          'employees.his_entry_has_been_added_to_the_table_you_can_find_him_there',
      }),
    );
  } catch (e) {
    yield put(actions.createEmployeeFailed());
  }
};

/**
 * Get employee list
 */
export const getEmployeeListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: IEmployeeModel[] = yield call(
      Api.Employee.list,
      action.payload,
    );
    yield put(actions.getEmployeeListSuccess(response));
  } catch (e) {
    yield put(actions.getEmployeeListFailed());
  }
};

/**
 * Get removed employee list
 */
export const getRemovedEmployeeListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: IEmployeeModel[] = yield call(
      Api.Employee.list,
      action.payload,
    );
    yield put(actions.getRemovedEmployeeListSuccess(response));
  } catch (e) {
    yield put(actions.getRemovedEmployeeListFailed());
  }
};

/**
 * Get employee count
 */
export const getEmployeeCountRequestSaga = function* (
  action?: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ICountResponse = yield call(
      Api.Employee.count,
      action?.payload,
    );
    yield put(actions.getEmployeeCountSuccess(response));
  } catch (e) {
    yield put(actions.getEmployeeCountFailed());
  }
};

/**
 * Get removed employee count
 */
export const getRemovedEmployeeCountRequestSaga = function* (
  action?: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ICountResponse = yield call(
      Api.Employee.count,
      action?.payload,
    );
    yield put(actions.getRemovedEmployeeCountSuccess(response));
  } catch (e) {
    yield put(actions.getRemovedEmployeeCountFailed());
  }
};
/**
 * Restore employee
 */
export const restoreEmployeeRequestSaga = function* (
  action: ISagaAction<IUpdateEmployee & { deletedAt: string | null }>,
): SagaIterator {
  try {
    yield call(Api.Employee.restore, action.payload);
    yield put(actions.restoreEmployeeSuccess(action.payload.id));
    yield put(
      actions.addProcessStatus({
        variant: 'success',
        message: 'employees.successfully_restored',
      }),
    );
  } catch (e) {
    yield put(actions.restoreEmployeeFailed());
    yield put(
      actions.addProcessStatus({
        variant: 'error',
        message: 'employees.failed_restored',
      }),
    );
  }
};

/**
 * Delete employees
 */
export const deleteEmployeeRequestSaga = function* (
  action: ISagaAction<PayloadWithFilters<IdsArray>>,
): SagaIterator {
  try {
    yield call(Api.Employee.delete, {
      where: { id: { inq: action.payload.data } },
    });
    yield put(
      actions.getEmployeeListRequest({
        filter: action.payload.filters.list,
      }),
    );
    yield put(
      actions.getEmployeeCountRequest({
        filter: action.payload.filters.count,
      }),
    );
    yield put(
      actions.addProcessStatus({
        variant: 'success',
        title: 'employees.employee_was_successfully_removed',
      }),
    );
  } catch (e) {
    yield put(actions.deleteEmployeeFailed());
  }
};

/**
 * Bulk update employees
 */
export const updateEmployeesRequestSaga = function* ({
  payload,
}: ISagaAction<PayloadWithFilters<IUpdateEmployee[]>>): SagaIterator {
  try {
    yield call(Api.Employee.bulkUpdate, payload.data);
    yield put(actions.getEmployeeListRequest({ filter: payload.filters.list }));
    yield put(
      actions.getEmployeeCountRequest({ filter: payload.filters.count }),
    );
    yield put(
      actions.addProcessStatus({
        variant: 'success',
        title: 'employees.employee_was_successfully_updated',
      }),
    );
  } catch (e) {
    yield put(actions.updateEmployeesFailed());
  }
};

/**
 * Get combobox list
 */
export const getEmployeeComboboxListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: Partial<IEmployeeModel>[] = yield call(
      Api.Employee.list,
      action.payload,
    );
    yield put(actions.getEmployeeComboboxListSuccess(response));
  } catch (e) {
    yield put(actions.getEmployeeComboboxListFailed());
  }
};

/**
 * Get active but not present employees list
 */
export const getEmployeeActiveButNotPresentListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: IEmployeesActiveButNotPresentModel[] = yield call(
      Api.Employee.activeButNotPresentList,
      action.payload,
    );
    yield put(actions.getEmployeeActiveButNotPresentListSuccess(response));
  } catch (e) {
    yield put(actions.getEmployeeActiveButNotPresentListFailed());
  }
};

/**
 * Get active but not present employees count
 */
export const getEmployeeActiveButNotPresentCountRequestSaga = function* (
  action?: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ICountResponse = yield call(
      Api.Employee.activeButNotPresentCount,
      action?.payload,
    );
    yield put(actions.getEmployeeActiveButNotPresentCountSuccess(response));
  } catch (e) {
    yield put(actions.getEmployeeActiveButNotPresentCountFailed());
  }
};

/**
 * Get terminated employees list
 */
export const getEmployeeTerminatedListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: IEmployeesTerminatedModel[] = yield call(
      Api.Employee.terminatedList,
      action.payload,
    );
    yield put(actions.getEmployeeTerminatedListSuccess(response));
  } catch (e) {
    yield put(actions.getEmployeeTerminatedListFailed());
  }
};

/**
 * Get terminated employees count
 */
export const getEmployeeTerminatedCountRequestSaga = function* (
  action?: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ICountResponse = yield call(
      Api.Employee.terminatedCount,
      action?.payload,
    );
    yield put(actions.getEmployeeTerminatedCountSuccess(response));
  } catch (e) {
    yield put(actions.getEmployeeTerminatedCountFailed());
  }
};

/**
 * Get HookyFolks list
 */
export const getHookyFolksListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: IHookyFolksModel[] = yield call(
      Api.Employee.hookyFolksList,
      action.payload,
    );
    yield put(actions.getHookyFolksListSuccess(response));
  } catch (e) {
    yield put(actions.getHookyFolksListFailed());
  }
};

/**
 * Get HookyFolks count
 */
export const getHookyFolksCountRequestSaga = function* (
  action?: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ICountResponse = yield call(
      Api.Employee.hookyFolksCount,
      action?.payload,
    );
    yield put(actions.getHookyFolksCountSuccess(response));
  } catch (e) {
    yield put(actions.getHookyFolksCountFailed());
  }
};

/**
 * Get HistoricalHookyFolks list
 */
export const getHistoricalHookyFolksListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: IHistoricalHookyFolksModel[] = yield call(
      Api.Employee.historicalHookFolksList,
      action.payload,
    );
    yield put(actions.getHistoricalHookyFolksListSuccess(response));
  } catch (e) {
    yield put(actions.getHistoricalHookyFolksListFailed());
  }
};

/**
 * Get HistoricalHookyFolks count
 */
export const getHistoricalHookyFolksCountRequestSaga = function* (
  action?: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ICountResponse = yield call(
      Api.Employee.historicalHookFolksCount,
      action?.payload,
    );
    yield put(actions.getHistoricalHookyFolksCountSuccess(response));
  } catch (e) {
    yield put(actions.getHistoricalHookyFolksCountFailed());
  }
};

/**
 * Export terminated employees
 */
const {
  exportRequestSaga: exportEmployeeTerminatedRequestSaga,
} = new ExportSagaCreator({
  apiCall: Api.Employee.exportEmployeeTerminated,
  actionFailed: actions.exportEmployeeTerminatedFailed,
  actionSuccess: actions.exportEmployeeTerminatedSuccess,
  actionNotification: actions.addProcessStatus,
}).takeSagas();

const {
  exportRequestSaga: exportHookyFolksRequestSaga,
} = new ExportSagaCreator({
  apiCall: Api.Employee.exportHookyFolks,
  actionFailed: actions.exportHookyFolksFailed,
  actionSuccess: actions.exportHookyFolksSuccess,
  actionNotification: actions.addProcessStatus,
}).takeSagas();

const {
  exportRequestSaga: exportHistoricalHookyFolksRequestSaga,
} = new ExportSagaCreator({
  apiCall: Api.Employee.exportHistoricalHookyFolks,
  actionFailed: actions.exportHistoricalHookyFolksFailed,
  actionSuccess: actions.exportHistoricalHookyFolksSuccess,
  actionNotification: actions.addProcessStatus,
}).takeSagas();

const { exportRequestSaga } = new ExportSagaCreator({
  apiCall: Api.Employee.export,
  actionFailed: actions.exportEmployeeFailed,
  actionSuccess: actions.exportEmployeeSuccess,
  actionNotification: actions.addProcessStatus,
}).takeSagas();
export const exportEmployeeRequestSaga = exportRequestSaga;

const {
  exportRequestSaga: exportEmployeeActiveButNotPresentRequestSaga,
} = new ExportSagaCreator({
  apiCall: Api.Employee.exportEmployeeActiveButNotPresent,
  actionFailed: actions.exportEmployeeActiveButNotPresentFailed,
  actionSuccess: actions.exportEmployeeActiveButNotPresentSuccess,
  actionNotification: actions.addProcessStatus,
}).takeSagas();

export const autobind: ISagaActionBind<any>[] = [
  { action: actions.createEmployeeRequest, saga: createEmployeeRequestSaga },
  { action: actions.getEmployeeListRequest, saga: getEmployeeListRequestSaga },
  {
    action: actions.getEmployeeCountRequest,
    saga: getEmployeeCountRequestSaga,
  },
  { action: actions.deleteEmployeeRequest, saga: deleteEmployeeRequestSaga },
  // bulk updating employee
  { action: actions.updateEmployeesRequest, saga: updateEmployeesRequestSaga },
  {
    action: actions.getEmployeeComboboxListRequest,
    saga: getEmployeeComboboxListRequestSaga,
  },
  { action: actions.exportEmployeeRequest, saga: exportEmployeeRequestSaga },
  {
    action: actions.getEmployeeActiveButNotPresentListRequest,
    saga: getEmployeeActiveButNotPresentListRequestSaga,
  },
  {
    action: actions.getEmployeeActiveButNotPresentCountRequest,
    saga: getEmployeeActiveButNotPresentCountRequestSaga,
  },
  {
    action: actions.exportEmployeeActiveButNotPresentRequest,
    saga: exportEmployeeActiveButNotPresentRequestSaga,
  },
  {
    action: actions.getEmployeeTerminatedListRequest,
    saga: getEmployeeTerminatedListRequestSaga,
  },
  {
    action: actions.getEmployeeTerminatedCountRequest,
    saga: getEmployeeTerminatedCountRequestSaga,
  },
  {
    action: actions.getHookyFolksListRequest,
    saga: getHookyFolksListRequestSaga,
  },
  {
    action: actions.getHookyFolksCountRequest,
    saga: getHookyFolksCountRequestSaga,
  },
  {
    action: actions.getHistoricalHookyFolksListRequest,
    saga: getHistoricalHookyFolksListRequestSaga,
  },
  {
    action: actions.getHistoricalHookyFolksCountRequest,
    saga: getHistoricalHookyFolksCountRequestSaga,
  },
  {
    action: actions.exportEmployeeTerminatedRequest,
    saga: exportEmployeeTerminatedRequestSaga,
  },
  {
    action: actions.exportHookyFolksRequest,
    saga: exportHookyFolksRequestSaga,
  },
  {
    action: actions.exportHistoricalHookyFolksRequest,
    saga: exportHistoricalHookyFolksRequestSaga,
  },
  {
    action: actions.getRemovedEmployeeListRequest,
    saga: getRemovedEmployeeListRequestSaga,
  },
  {
    action: actions.getRemovedEmployeeCountRequest,
    saga: getRemovedEmployeeCountRequestSaga,
  },
  {
    action: actions.restoreEmployeeRequest,
    saga: restoreEmployeeRequestSaga,
  },
];
