import { Box, Button } from '@mui/joy';
import { map } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { MainContentLoader } from 'src/components/Layout/components/PageTour/MainContentLoader';
import {
  Pagination,
  TableTitle,
  TableBottom,
  TableActions,
  TableContent,
  TableToolbar,
  TableComponent,
  TableContextProvider,
} from 'src/components/Table';
import { Filter } from 'src/components/Table/components/TableActions/components/Filter';
import { TextFieldFilter } from 'src/components/Table/components/TableActions/components/Filter/components/TextFieldFilter';
import { SelectHeadCells } from 'src/components/Table/components/TableActions/components/SelectHeadCells';
import { TableSelectedItemsActions } from 'src/components/Table/components/TableSelectedItemsActions';
import { Edit02Svg, Trash04Svg } from 'src/components/svgIcons';
import { ModelsToDelete, useHasUserAccessToAction } from 'src/config';
import { manageEntitiesConfig } from 'src/config/manageEntitiesConfig';
import {
  getSiteImportingSettingsListRequest,
  getSiteImportingSettingsCountRequest,
  deleteSiteImportingSettingsRequest,
} from 'src/modules/actions';
import { IHeadCellWithOrderConfig } from 'src/modules/types/table';
import {
  useTableContentEffect,
  useGenerateHeadCellsData,
  useGenerateRequestFilter,
  useTableDataWithQueryParamsSynchronization,
  useFilterFieldsData,
} from 'src/modules/utils/hooks/table';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { CheckboxFilter } from 'src/components/Table/components/TableActions/components/Filter/components/CheckboxFilter';
import {
  getGloballySelectedSites,
  getSitesComboboxList,
} from 'src/modules/selectors/site';
import { ComboboxTableFilter } from 'src/components/Table/components/TableActions/components/Filter/components/ComboboxTableFilter';
import { ClientKeysComboBoxFilter } from 'src/components/Table/components/TableActions/components/Filter/components/ClientKeysComboboxFilter';
import {
  getIsSiteImportingSettingsDataLoading,
  getSiteImportingSettingsCount,
  getSiteImportingSettingsTableWithParsedRows,
} from 'src/modules/selectors/site-importing-settings.selector';
import { CreateImportingSetting } from './CreateImportingSetting';
import { UpdateImportingSetting } from './UpdateImportingSetting';

export const ImportingSettings = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const globallySelectedSiteIds = useSelector(getGloballySelectedSites);

  const [selectedEntitiesIds, setSelectedEntitiesIds] = React.useState<
    Array<number>
  >([]);
  const [isCreateFormOpened, setIsCreateFormOpened] = React.useState(false);
  const [isUpdateFormOpened, setIsUpdateFormOpened] = React.useState(false);

  const isDataLoading = useSelector(getIsSiteImportingSettingsDataLoading);

  const allowedToCreate = useHasUserAccessToAction(
    manageEntitiesConfig.importing_settings.create.id,
  );
  const allowedToUpdate = useHasUserAccessToAction(
    manageEntitiesConfig.importing_settings.update.id,
  );
  const allowedToDelete = useHasUserAccessToAction(
    manageEntitiesConfig.importing_settings.delete.id,
  );

  const sites = useSelector(getSitesComboboxList);

  const inclusionObj = React.useMemo(() => {
    return {
      site: {
        relationType: 'left',
        scope: {
          where: {
            id: {
              inq: globallySelectedSiteIds,
            },
          },
        },
      },
    };
  }, [globallySelectedSiteIds]);

  const headCellsConfig: IHeadCellWithOrderConfig[] = React.useMemo(
    () => [
      {
        id: 'clientKey',
        orderConfig: { orderBy: 'clientKey' },
        label: t('site-importing-settings.clientKey'),
      },
      {
        id: 'site.name',
        orderConfig: {
          orderBy: 'name',
          orderByInclusionPath: ['site', 'scope'],
        },
        label: t('site-importing-settings.site'),
      },
      {
        id: 'productionRepositoryKey',
        orderConfig: { orderBy: 'productionRepositoryKey' },
        label: t('site-importing-settings.productionRepositoryKey'),
      },
      {
        id: 'weeklyCpuKey',
        orderConfig: { orderBy: 'weeklyCpuKey' },
        label: t('site-importing-settings.weeklyCpuKey'),
      },
      {
        id: 'customCostPlusKey',
        orderConfig: { orderBy: 'v' },
        label: t('site-importing-settings.customCostPlusKey'),
      },
      {
        id: 'transformerKey',
        orderConfig: { orderBy: 'transformerKey' },
        label: t('site-importing-settings.transformerKey'),
      },
      {
        id: 'aggregationKey',
        orderConfig: { orderBy: 'aggregationKey' },
        label: t('site-importing-settings.aggregationKey'),
      },
      {
        id: 'hoursRepositoryKey',
        orderConfig: { orderBy: 'hoursRepositoryKey' },
        label: t('site-importing-settings.hoursRepositoryKey'),
      },
      {
        id: 'googleCloudKeyPath',
        orderConfig: { orderBy: 'googleCloudKeyPath' },
        label: t('site-importing-settings.googleCloudKeyPath'),
      },
      {
        id: 'transformOnSync',
        orderConfig: { orderBy: 'transformOnSync' },
        label: t('site-importing-settings.transformOnSync'),
      },
      {
        id: 'isLocalInfile',
        orderConfig: { orderBy: 'isLocalInfile' },
        label: t('site-importing-settings.isLocalInfile'),
      },
      {
        id: 'fileRegex',
        orderConfig: { orderBy: 'fileRegex' },
        label: t('site-importing-settings.fileRegex'),
      },
      {
        id: 'tableType',
        orderConfig: { orderBy: 'tableType' },
        label: t('site-importing-settings.tableType'),
      },
      {
        id: 'bucket',
        orderConfig: { orderBy: 'bucket' },
        label: t('site-importing-settings.bucket'),
      },
      {
        id: 'createdAt',
        orderConfig: {
          orderBy: 'createdAt',
        },
      },
    ],
    [t],
  );
  const { headCells, headCellsOrderDetails } = useGenerateHeadCellsData(
    headCellsConfig,
  );
  const filterFieldsConfiguration = React.useMemo(
    () => ({
      clientKey: {
        value: '',
        property: 'clientKey',
        operator: 'eq' as const,
      },
      siteUuid: {
        value: '',
        property: 'siteUuid',
        operator: 'eq' as const,
      },
      productionRepositoryKey: {
        value: '',
        property: 'productionRepositoryKey',
        operator: 'like' as const,
      },
      weeklyCpuKey: {
        value: '',
        property: 'weeklyCpuKey',
        operator: 'like' as const,
      },
      transformerKey: {
        value: '',
        property: 'transformerKey',
        operator: 'like' as const,
      },
      aggregationKey: {
        value: '',
        property: 'aggregationKey',
        operator: 'like' as const,
      },
      hoursRepositoryKey: {
        value: '',
        property: 'hoursRepositoryKey',
        operator: 'like' as const,
      },
      transformOnSync: {
        value: '',
        property: 'transformOnSync',
        operator: 'eq' as const,
      },
      googleCloudKeyPath: {
        value: '',
        property: 'googleCloudKeyPath',
        operator: 'like' as const,
      },
      fileRegex: {
        value: '',
        property: 'fileRegex',
        operator: 'like' as const,
      },
      tableType: {
        value: '',
        property: 'tableType',
        operator: 'like' as const,
      },
      bucket: {
        value: '',
        property: 'bucket',
        operator: 'like' as const,
      },
    }),
    [],
  );

  const {
    page,
    limit,
    order,
    orderBy,
    setPage,
    setLimit,
    setOrder,
    setOrderBy,
  } = useTableDataWithQueryParamsSynchronization({
    headCellsOrderDetails,
    defaultOrder: 'desc',
    defaultOrderBy: 'createdAt',
  });

  const {
    filterFields,
    getLabel,
    // clearFilters,
    // clearFilterFieldsData,
    onFiltersFormSubmit,
    getFilterCommonPropsByFilterName,
  } = useFilterFieldsData({
    filterFieldsConfiguration,
  });

  const filter = useGenerateRequestFilter({
    page,
    limit,
    order,
    orderBy,
    filterFields,
    inclusionObj,
    headCellsOrderDetails,
    filterFieldsConfiguration,
  });

  const { data, count } = useTableContentEffect({
    filter,
    selectData: getSiteImportingSettingsTableWithParsedRows,
    selectCount: getSiteImportingSettingsCount,
    getEntitiesRequest: getSiteImportingSettingsListRequest,
    getEntitiesCountRequest: getSiteImportingSettingsCountRequest,
  });

  const onUpdateClick = () => {
    setIsUpdateFormOpened(true);
  };

  const onDeleteClick = () => {
    dispatch(
      deleteSiteImportingSettingsRequest({
        data: selectedEntitiesIds,
        filters: {
          list: filter,
          count: { where: filter.where, include: filter.include },
        },
      }),
    );

    setSelectedEntitiesIds([]);
  };

  const actionsConfiguration = [];
  if (allowedToUpdate) {
    actionsConfiguration.push({
      tooltip: t('common.update'),
      onClick: onUpdateClick,
      icon: <Edit02Svg />,
    });
  }

  return (
    <>
      <TableContextProvider>
        <TableToolbar>
          <TableTitle>{t('site-importing-settings.main')}</TableTitle>
          <TableActions>
            <Filter onSubmit={onFiltersFormSubmit}>
              <FormFieldContainer>
                <ClientKeysComboBoxFilter
                  label={getLabel({
                    filterName: 'clientKey',
                    labelPrefix: t('site-importing-settings.clientKey'),
                  })}
                  {...getFilterCommonPropsByFilterName('clientKey')}
                />
              </FormFieldContainer>
              <FormFieldContainer>
                <ComboboxTableFilter
                  label={getLabel({
                    filterName: 'siteUuid',
                    labelPrefix: t('site-importing-settings.site'),
                  })}
                  options={map(
                    sites,
                    (site) =>
                      ({
                        id: site.uuid,
                        name: site.name,
                      } as any),
                  )}
                  {...getFilterCommonPropsByFilterName('siteUuid')}
                />
              </FormFieldContainer>
              <FormFieldContainer>
                <TextFieldFilter
                  label={getLabel({
                    filterName: 'productionRepositoryKey',
                    labelPrefix: t(
                      'site-importing-settings.productionRepositoryKey',
                    ),
                  })}
                  {...getFilterCommonPropsByFilterName(
                    'productionRepositoryKey',
                  )}
                />
              </FormFieldContainer>
              <FormFieldContainer>
                <TextFieldFilter
                  label={getLabel({
                    filterName: 'weeklyCpuKey',
                    labelPrefix: t('site-importing-settings.weeklyCpuKey'),
                  })}
                  {...getFilterCommonPropsByFilterName('weeklyCpuKey')}
                />
              </FormFieldContainer>
              <FormFieldContainer>
                <TextFieldFilter
                  label={getLabel({
                    filterName: 'transformerKey',
                    labelPrefix: t('site-importing-settings.transformerKey'),
                  })}
                  {...getFilterCommonPropsByFilterName('transformerKey')}
                />
              </FormFieldContainer>
              <FormFieldContainer>
                <TextFieldFilter
                  label={getLabel({
                    filterName: 'hoursRepositoryKey',
                    labelPrefix: t(
                      'site-importing-settings.hoursRepositoryKey',
                    ),
                  })}
                  {...getFilterCommonPropsByFilterName('hoursRepositoryKey')}
                />
              </FormFieldContainer>
              <FormFieldContainer>
                <TextFieldFilter
                  label={getLabel({
                    filterName: 'googleCloudKeyPath',
                    labelPrefix: t(
                      'site-importing-settings.googleCloudKeyPath',
                    ),
                  })}
                  {...getFilterCommonPropsByFilterName('googleCloudKeyPath')}
                />
              </FormFieldContainer>
              <FormFieldContainer>
                <TextFieldFilter
                  label={getLabel({
                    filterName: 'fileRegex',
                    labelPrefix: t('site-importing-settings.fileRegex'),
                  })}
                  {...getFilterCommonPropsByFilterName('fileRegex')}
                />
              </FormFieldContainer>
              <FormFieldContainer>
                <TextFieldFilter
                  label={getLabel({
                    filterName: 'tableType',
                    labelPrefix: t('site-importing-settings.tableType'),
                  })}
                  {...getFilterCommonPropsByFilterName('tableType')}
                />
              </FormFieldContainer>
              <FormFieldContainer>
                <TextFieldFilter
                  label={getLabel({
                    filterName: 'bucket',
                    labelPrefix: t('site-importing-settings.bucket'),
                  })}
                  {...getFilterCommonPropsByFilterName('bucket')}
                />
              </FormFieldContainer>
              <FormFieldContainer>
                <CheckboxFilter
                  label={getLabel({
                    filterName: 'transformOnSync',
                    labelPrefix: t('site-importing-settings.transformOnSync'),
                  })}
                  {...getFilterCommonPropsByFilterName('transformOnSync')}
                />
              </FormFieldContainer>
            </Filter>

            <SelectHeadCells />

            {allowedToCreate && (
              <Button
                sx={{ ml: 1 }}
                onClick={() => setIsCreateFormOpened(true)}
              >
                {t('common.create')}
              </Button>
            )}
          </TableActions>
        </TableToolbar>

        <Box sx={{ flexGrow: 1, height: '100%' }}>
          <TableComponent
            tableUniqueKey="setup/importing_settings"
            sx={{
              flexGrow: 0,
              height: isDataLoading || !data.length ? '100%' : 'auto',
              maxHeight: `calc(100vh - var(--bottom-copyright-height) - 116px)`,
            }}
          >
            {isDataLoading && <MainContentLoader />}

            <TableContent
              data={data}
              order={order}
              orderBy={orderBy}
              headCells={headCells}
              isDataLoading={isDataLoading}
              onRowCheckboxClick={(ids) => {
                setSelectedEntitiesIds(ids);
              }}
              selectedRows={selectedEntitiesIds}
              onSort={(orderBy, order) => {
                setOrder(order);
                setOrderBy(orderBy);
              }}
            />

            {Boolean(selectedEntitiesIds.length) && (
              <TableSelectedItemsActions
                selectedItemsQuantity={selectedEntitiesIds.length}
                actionsConfiguration={actionsConfiguration}
                deleteModelName={ModelsToDelete.SiteImportingSetting}
                deleteActionConfiguration={
                  allowedToDelete
                    ? {
                        tooltip: t('common.delete'),
                        onClick: onDeleteClick,
                        icon: <Trash04Svg />,
                      }
                    : undefined
                }
              />
            )}

            <TableBottom>
              <Pagination
                currentPageNumber={page}
                count={count}
                rowsPerPage={limit}
                onPageChange={setPage}
                onRowsPerPageChange={setLimit}
              />
            </TableBottom>
          </TableComponent>
        </Box>
      </TableContextProvider>

      <CreateImportingSetting
        isOpen={isCreateFormOpened}
        onClose={() => setIsCreateFormOpened(false)}
        filterList={filter}
        filterCount={{
          where: filter.where,
          include: filter.include,
        }}
      />

      <UpdateImportingSetting
        isOpen={isUpdateFormOpened}
        onClose={() => setIsUpdateFormOpened(false)}
        filterList={filter}
        filterCount={{
          where: filter.where,
          include: filter.include,
        }}
        entitiesIds={selectedEntitiesIds}
      />
    </>
  );
};
