import * as React from 'react';
import { isEmpty, differenceWith, isMatch } from 'lodash';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation, Outlet, Navigate } from 'react-router-dom';

import { isLoggedIn } from 'src/modules/selectors/auth';
import { getUserPolicies, getUserRoles } from 'src/modules/selectors/user';
import { paths, roles, skipAuthorization, tabPrefix } from 'src/config';
import MainLayout from '../Layout/MainLayout';
import { isUserTokenExpired } from 'src/modules/utils/user';
import { logoutRequest } from 'src/modules/actions';
import Error404 from 'src/pages/Error404';
import { usePathPattern } from 'src/modules/utils/hooks/usePathPattern';
import { getPageSettingPolicies } from 'src/modules/selectors/page-setting.selector';
import { PolicyConfigItem } from 'src/modules/types';
import { useMakeFlatMenuItems } from 'src/modules/utils/hooks/menu.hooks';

const reportsPageRegexp = /reports$/;

export const PrivateRoute = () => {
  const dispatcher = useDispatch();
  const POLICIES = useSelector(getPageSettingPolicies, shallowEqual);
  // check if a user is logged in
  const isLogged = useSelector(isLoggedIn, shallowEqual);
  // get list of roles attached to the user
  const userRoles = useSelector(getUserRoles, shallowEqual);
  // get user policies
  const userPolicies = useSelector(getUserPolicies, shallowEqual);
  const { pathname, search } = useLocation();
  const pattern = usePathPattern();

  const flattenMenuItems = useMakeFlatMenuItems();

  const componentToRender = (
    <MainLayout>
      <Outlet />
    </MainLayout>
  );

  React.useEffect(() => {
    if (isUserTokenExpired()) {
      dispatcher(logoutRequest());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isLogged) {
    return (
      <Navigate
        to={{
          pathname: paths.LOGIN,
        }}
        state={{ referrer: pathname + search }}
      />
    );
  }

  // if the current user is admin, we allow the action (other part of authorization logic is on the server side)
  if (userRoles && userRoles.includes(roles.SUPER_ADMIN)) {
    return componentToRender;
  }
  const isMenuItemWithTab = flattenMenuItems.some(
    (menuItem) =>
      menuItem.url &&
      pattern.match(menuItem.url) &&
      menuItem.pageType === 'withTabs',
  );

  const policies = POLICIES[pattern];
  if (
    !skipAuthorization.includes(`${pathname}`) &&
    isEmpty(policies) &&
    !isMenuItemWithTab
  ) {
    return <Error404 />;
  }
  if (policies && userPolicies) {
    // try to determine if the user has passed policy and can deal with the route,
    // by default user has to have all polices required by page
    const foundPolicies = differenceWith(policies, userPolicies, isMatch);

    // For pages with tabs in dynamic page config we will have shallow paths
    // with "/tab" prefix. To render this menu we need to check that user has
    // access to at least one tab
    if (isMenuItemWithTab) {
      const tabsRequiredPolices = Object.entries(POLICIES).reduce<
        Array<Array<PolicyConfigItem>>
      >((acc, [path, requiredPolicies]) => {
        if (path.startsWith(`${pattern}${tabPrefix}`)) {
          acc.push(requiredPolicies);
        }

        return acc;
      }, []);

      const hasUserRequiredPoliciesAtLeastForOneTab = tabsRequiredPolices.some(
        (tabPolicies) =>
          tabPolicies.every((tabPolicy) =>
            userPolicies.some((userPolicy) => isMatch(tabPolicy, userPolicy)),
          ),
      );

      return hasUserRequiredPoliciesAtLeastForOneTab ? (
        componentToRender
      ) : (
        <Error404 />
      );
    }

    const menuItemForPathPattern = flattenMenuItems.find(
      (menuItem) => menuItem.url && pattern.match(menuItem.url),
    );
    // for report page we will render the page in
    // case user has access to at least one report
    // all the other cases will be handled on report page dropdowns side
    if (pattern.match(reportsPageRegexp) || menuItemForPathPattern) {
      const isSomeReportedAllowedToView = userPolicies.some((policy) =>
        policies.some((requiredPolicy) => isMatch(policy, requiredPolicy)),
      );
      if (isSomeReportedAllowedToView) {
        return componentToRender;
      }
    }

    // check it the logged in user is permitted to deal with the route
    if (!isEmpty(foundPolicies)) {
      return <Error404 />;
    }
  }

  return componentToRender;
};
