import { JSONSchemaType } from 'ajv';
import { ICreateEmployee } from '../../types';
// don't make this shorter since builder might face with circular dependencies
import { i18n } from '../../utils/i18n';
import { addDays, format } from 'date-fns';
import { DATE_FORMAT } from 'src/modules/utils/dateWrapper';

export const maxHiredDate = addDays(new Date(), 30);

export const payRateRegex =
  '^[+-]?(30(\\.00)?|[0-2]?[0-9]{0,1}(\\.\\d{1,2})?)$';

export const createEmployeeScheme: JSONSchemaType<
  ICreateEmployee & { parentId?: number }
> = {
  type: 'object',
  required: [
    'employeeId',
    'siteId',
    'positionId',
    'staffingId',
    'supervisorId',
    'firstName',
    'lastName',
    'payRate',
    'dateHired',
    'badge',
    'defaultShiftId',
    'defaultDepartmentId',
  ],
  additionalProperties: false,
  errorMessage: {
    required: i18n.t('error.validation.required'),
  },
  properties: {
    employeeId: {
      default: '',
      isNotEmpty: true,
      type: 'string',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    positionId: {
      default: NaN,
      isNotEmpty: true,
      type: 'number',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
        type: i18n.t('error.validation.required'),
      },
    },
    staffingId: {
      default: NaN,
      isNotEmpty: true,
      type: 'number',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
        type: i18n.t('error.validation.required'),
      },
    },
    supervisorId: {
      default: NaN,
      isNotEmpty: true,
      type: 'number',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.must_be_an_active_supervisor'),
        type: i18n.t('error.validation.must_be_an_active_supervisor'),
      },
    },
    siteId: {
      default: NaN,
      isNotEmpty: true,
      type: 'number',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
        type: i18n.t('error.validation.required'),
      },
    },
    defaultShiftId: {
      default: NaN,
      isNotEmpty: true,
      type: 'number',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
        type: i18n.t('error.validation.required'),
      },
    },
    defaultDepartmentId: {
      default: NaN,
      isNotEmpty: true,
      type: 'number',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
        type: i18n.t('error.validation.required'),
      },
    },
    firstName: {
      default: '',
      isNotEmpty: true,
      type: 'string',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    lastName: {
      default: '',
      isNotEmpty: true,
      type: 'string',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    payRate: {
      type: 'string',
      pattern: payRateRegex,
      isNotEmpty: true,
      errorMessage: {
        type: i18n.t('error.validation.required'),
        isNotEmpty: i18n.t('error.validation.required'),
        pattern: i18n.t('error.validation.payRate'),
      },
    },
    dateHired: {
      default: null,
      isNotEmpty: true,
      type: 'string',
      dateLessThen: {
        date: maxHiredDate,
      },
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
        type: i18n.t('error.validation.required'),
        dateLessThen: i18n.t('error.validation.less_than', {
          date: format(maxHiredDate, DATE_FORMAT),
        } as any),
      },
    },
    badge: {
      default: NaN,
      isNotEmpty: true,
      type: 'number',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
        type: i18n.t('error.validation.number'),
      },
    },
    separationdate: {
      nullable: true,
      type: 'string',
    },
    parentId: {
      nullable: true,
      type: 'number',
    },
    active: {
      nullable: true,
      type: 'boolean',
    },
    address: {
      nullable: true,
      type: 'string',
    },
    phonenumber: {
      nullable: true,
      type: 'string',
    },
    email: {
      nullable: true,
      type: 'string',
    },
    dateentered: {
      nullable: true,
      type: 'string',
    },
    last4SSN: {
      nullable: true,
      type: 'number',
    },
    wmsLogin: {
      nullable: true,
      type: 'string',
    },
    taktId: {
      nullable: true,
      type: 'string',
    },
    terminationReasonId: {
      nullable: true,
      type: 'number',
    },
    canRehire: {
      nullable: true,
      type: 'boolean',
    },
    isWmsLoginPrinted: {
      nullable: true,
      type: 'boolean',
    },
    isKronosAudited: {
      nullable: true,
      type: 'boolean',
    },
    isYMSAudited: {
      nullable: true,
      type: 'boolean',
    },
    isOtherAudited: {
      nullable: true,
      type: 'boolean',
    },
    wotc: {
      nullable: true,
      type: 'boolean',
    },
    emergencyContact: {
      nullable: true,
      type: 'string',
    },
    comments: {
      nullable: true,
      type: 'string',
    },
    hashSSN: {
      nullable: true,
      type: 'string',
    },
    inactivatedAt: {
      nullable: true,
      type: 'string',
    },
    file: {
      nullable: true,
      type: 'object',
      required: [],
    },
  },
  anyOf: [
    {
      if: {
        properties: {
          active: {
            not: {
              const: false,
            },
          },
        },
      },
      then: {
        properties: {
          isKronosAudited: {
            const: false,
            errorMessage: {
              const: i18n.t('error.validation.for_inactive_employee'),
            },
          },
          isYMSAudited: {
            const: false,
            errorMessage: {
              const: i18n.t('error.validation.for_inactive_employee'),
            },
          },
          isOtherAudited: {
            const: false,
            errorMessage: {
              const: i18n.t('error.validation.for_inactive_employee'),
            },
          },
        },
      },
    },
  ],
};
