import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerBodySectionContent,
  DrawerBodySectionTitle,
  DrawerBodyStepper,
  DrawerBodyStepperContent,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { useTranslation } from 'react-i18next';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { StepStatus } from 'src/components/_ui-kit/Steps';
import { ProgressStep } from 'src/components/_ui-kit/Steps/ProgressStep';
import { useFormik } from 'formik';
import { useBrowserHistoryFunctions, useValidate } from 'src/modules/utils';
import { updateEmployeesScheme } from 'src/modules/schemes/employees';
import { useDispatch, useSelector } from 'react-redux';
import { updateEmployeesRequest } from 'src/modules/actions';
import {
  DATETIME_FORMAT_TO_PASS,
  composeDate,
  format,
} from 'src/modules/utils/dateWrapper';
import {
  IFilterData,
  IStoreState,
  IUpdateEmployee,
  IWhere,
  IdsArray,
} from 'src/modules/types';
import { getEmployeesByIds } from 'src/modules/selectors/employee';
import { UpdateEmployeeOptionalFields } from './formFields/UpdateEmployeesOptionalFields';
import { UpdateEmployeeRequireFields } from './formFields/UpdateEmployeesRequiredFields';
import { generateFormDataFieldsForUpdateEntityForm } from '../../../../modules/utils/helpers/form';
import { omit } from 'lodash';

const stepsIds = {
  requiredFields: 'requiredFields' as const,
  optionalFields: 'optionalFields' as const,
};

const initialSteps = {
  [stepsIds.requiredFields]: 'complete' as const,
  [stepsIds.optionalFields]: 'incomplete' as const,
};

interface IUpdateEmployeesProps {
  isOpen: boolean;
  onClose: () => void;
  employeesToUpdateIds: IdsArray;
  filterList: IFilterData;
  filterCount: IWhere;
}

const requiredFieldsNames = [
  'employeeId',
  'siteId',
  'positionId',
  'staffingId',
  'supervisorId',
  'firstName',
  'lastName',
  'payRate',
  'dateHired',
  'badge',
  'defaultShiftId',
  'defaultDepartmentId',
];

export const UpdateEmployees = ({
  isOpen,
  onClose,
  filterList,
  filterCount,
  employeesToUpdateIds,
}: IUpdateEmployeesProps) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { pushSearchObject } = useBrowserHistoryFunctions();

  const employees = useSelector((state) =>
    getEmployeesByIds(state as IStoreState)(employeesToUpdateIds),
  );

  const stepsStaticData = React.useMemo(
    () => ({
      [stepsIds.requiredFields]: {
        id: stepsIds.requiredFields,
        title: t('common.required_details'),
        subtitle: t('employees.few_details_about_your_employee'),
      },
      [stepsIds.optionalFields]: {
        id: stepsIds.optionalFields,
        title: t('common.optional_details'),
        subtitle: t('common.additional_information'),
      },
    }),
    [t],
  );

  const [currentStepName, setCurrentStepName] = React.useState<
    typeof stepsIds[keyof typeof stepsIds]
  >(stepsIds.requiredFields);
  const [stepsStatuses, setStepsStatuses] = React.useState<
    {
      [key in typeof stepsIds[keyof typeof stepsIds]]: StepStatus;
    }
  >(initialSteps);

  const validate = useValidate(updateEmployeesScheme);

  const formik = useFormik({
    initialValues: employees.map((item) => ({
      ...item,
      payRate: String(item.payRate),
    })),
    validate,
    enableReinitialize: true,
    onSubmit: (data: IUpdateEmployee[]) => {
      if (currentStepName === 'optionalFields') {
        pushSearchObject({});

        const formData = generateFormDataFieldsForUpdateEntityForm(
          data.map((item) => ({
            ...omit(item, [
              'payRateChangedByUserId',
              'payRateChangedAt',
              'previousPayRate',
            ]),
            payRate: Number(item.payRate),
            inactivatedAt: item.active
              ? null
              : composeDate(new Date(), format(DATETIME_FORMAT_TO_PASS)),
            file: item.profilePhoto,
          })),
          ['profilePhoto'],
        );

        dispatch(
          updateEmployeesRequest({
            data: (formData as unknown) as IUpdateEmployee[],
            filters: {
              list: filterList,
              count: filterCount,
            },
          }),
        );

        onClose();

        formik.resetForm();

        setCurrentStepName(stepsIds.requiredFields);
      } else {
        setStepsStatuses((prev) => ({
          ...prev,
          [stepsIds.requiredFields]: 'complete',
        }));
        setCurrentStepName(stepsIds.optionalFields);
      }
    },
  });

  const requiredFields = formik.values.map((employee, index) => (
    <DrawerBodySectionContent key={employee.id}>
      <DrawerBodySectionTitle>{`${employee.firstName} ${employee.lastName}`}</DrawerBodySectionTitle>
      <UpdateEmployeeRequireFields key={index} index={index} formik={formik} />
    </DrawerBodySectionContent>
  ));

  const optionalFields = formik.values.map((employee, index) => (
    <DrawerBodySectionContent key={employee.id}>
      <DrawerBodySectionTitle>{`${employee.firstName} ${employee.lastName}`}</DrawerBodySectionTitle>
      <UpdateEmployeeOptionalFields key={index} index={index} formik={formik} />
    </DrawerBodySectionContent>
  ));

  const onStepClick = (stepId: typeof stepsIds[keyof typeof stepsIds]) => {
    setCurrentStepName(stepId);
  };

  const onReset = () => {
    formik.resetForm();
    setStepsStatuses(initialSteps);
  };

  React.useEffect(() => {
    const isRequiredFieldsHasError = Object.keys(formik.errors).some(
      (fieldName) =>
        formik.touched[fieldName] && requiredFieldsNames.includes(fieldName),
    );

    if (isRequiredFieldsHasError) {
      setStepsStatuses((prev) => ({
        ...prev,
        requiredFields: 'error',
      }));
    }
  }, [formik.errors, formik.touched]);

  return (
    <Drawer open={isOpen} onClose={onClose} anchor="right">
      {isOpen && (
        <>
          <DrawerHeader onCloseClick={onClose}>
            {t('employees.update')}
          </DrawerHeader>
          <DrawerBody>
            <DrawerBodyStepper>
              {Object.entries(stepsStatuses).map(([stepName, stepStatus]) => (
                <ProgressStep
                  key={stepName}
                  onClick={onStepClick}
                  status={stepName === currentStepName ? 'current' : stepStatus}
                  {...stepsStaticData[stepName]}
                />
              ))}
            </DrawerBodyStepper>
            <DrawerBodyStepperContent>
              {currentStepName === stepsIds.requiredFields && requiredFields}
              {currentStepName === stepsIds.optionalFields && optionalFields}
            </DrawerBodyStepperContent>
          </DrawerBody>
          <DrawerFooter>
            <ActionsBar
              onReset={onReset}
              onCancel={onClose}
              onApply={formik.handleSubmit}
              applyButtonType="submit"
            />
          </DrawerFooter>
        </>
      )}
    </Drawer>
  );
};
