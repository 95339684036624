import React, { useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import EnhancedTable, {
  HeadCell,
  ITableSyncProps,
} from '../../../../../components/EnhancedTable';
import { ITableFilter } from '../../../../../components/EnhancedTable/EnhancedTableFilter';
import { IdsArray } from '../../../../../modules/types';
import { useFilter } from '../../../../../modules/utils/hooks';
import { useTranslation } from 'react-i18next';
import {
  ModelsToDelete,
  useHasUserAccessToAction,
} from '../../../../../config';
import { useOtherCostsDefaultFilters } from '../../../../../modules/utils/hooks/other-costs.hooks';
import {
  getOtherCostsCount,
  getOtherCostsRefreshKey,
  getOtherCostsList,
  getIsOtherCostsDataLoading,
} from '../../../../../modules/selectors/other-costs.selector';
import {
  deleteOtherCostsRequest,
  getOtherCostsCountRequest,
  getOtherCostsListRequest,
} from '../../../../../modules/actions';
import CreateOtherCosts from './Forms/CreateOtherCosts';
import UpdateOtherCosts from './Forms/UpdateOtherCosts';
import { manageEntitiesConfig } from 'src/config/manageEntitiesConfig';
import { map } from 'lodash';
import { NoIcon, YesIcon } from '../../../../../components/Icons';

const OtherCostsList = () => {
  const { t } = useTranslation();

  const defaultFilter = useOtherCostsDefaultFilters();

  const hasUserAccessToCreateEntity = useHasUserAccessToAction(
    manageEntitiesConfig.other_costs.create.id,
  );
  const hasUserAccessToUpdateEntity = useHasUserAccessToAction(
    manageEntitiesConfig.other_costs.update.id,
  );
  const hasUserAccessToDeleteEntity = useHasUserAccessToAction(
    manageEntitiesConfig.other_costs.delete.id,
  );

  // fetch OtherCosts list
  const list = useSelector(getOtherCostsList, shallowEqual);
  const count = useSelector(getOtherCostsCount, shallowEqual);
  const isTableDataLoading = useSelector(getIsOtherCostsDataLoading);

  // create dispatcher
  const dispatcher = useDispatch();
  const { filterCount, filterList } = useFilter(defaultFilter);

  const [isCreateFormVisible, setIsCreateFormVisible] = React.useState<boolean>(
    false,
  );
  const [isUpdateFormVisible, setIsUpdateFormVisible] = React.useState<boolean>(
    false,
  );

  const [selectedIds, setSelectedIds] = React.useState<IdsArray>([]);

  const computedList = useMemo(
    () =>
      map(list, (item) => {
        return {
          ...item,
          isRepeatable: item.isRepeatable ? (
            <YesIcon color="primary" />
          ) : (
            <NoIcon color="primary" />
          ),
        };
      }),
    [list],
  );

  // compose table header cells
  const headCells: HeadCell[] = [
    { id: 'site.name', disablePadding: false, label: t('shifts.site_name') },
    {
      id: 'yearNumber',
      disablePadding: false,
      label: t('productions_uph_reports.year'),
    },
    {
      id: 'weekNumber',
      disablePadding: false,
      label: t('productions_uph_reports.week'),
    },
    {
      id: 'yearWeek',
      disablePadding: false,
      label: t('productions_uph_reports.year_week'),
    },
    {
      id: 'isRepeatable',
      disablePadding: false,
      label: t('productions_uph_reports.isRepeatable'),
    },
    {
      id: 'value',
      disablePadding: false,
      label: t('other-costs.value'),
    },
    {
      id: 'description',
      disablePadding: false,
      label: t('other-costs.description'),
    },
  ];

  // table filters
  const filters: ITableFilter[] = [
    {
      name: 'siteId',
      label: t('shifts.site_name'),
      operator: 'eq',
      type: 'comboboxSites',
    },
    {
      name: 'yearNumber',
      label: t('productions_uph_reports.year'),
      operator: 'eq',
    },
    {
      name: 'weekNumber',
      label: t('productions_uph_reports.week'),
      operator: 'eq',
    },
    {
      name: 'description',
      label: t('issues.description'),
      operator: 'like',
    },
  ];

  // make request to fetch siteImportingSettings when component is mounted
  useEffect(() => {
    dispatcher(getOtherCostsListRequest(filterList));
    // get total count
    dispatcher(getOtherCostsCountRequest(filterCount));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultFilter]); // dispatch the action only once

  // handle table synchronization
  const onSync = (props: ITableSyncProps) => {
    const { order, page, rowsPerPage, where } = props;
    const offset = page * rowsPerPage;
    dispatcher(
      getOtherCostsListRequest({
        filter: {
          limit: rowsPerPage,
          offset,
          where,
          order,
          include: [
            {
              relation: 'site',
            },
          ],
        },
      }),
    );
    // update count accordingly to applied filters
    dispatcher(
      getOtherCostsCountRequest({
        filter: { where },
      }),
    );
  };

  // handle deletion
  const handleDeletion = (ids: IdsArray) => {
    dispatcher(
      deleteOtherCostsRequest({
        data: { ids },
        filters: {
          list: filterList.filter ?? {},
          count: filterCount.filter ?? {},
        },
      }),
    );
  };

  // handle updating
  const handleUpdating = (ids: IdsArray) => {
    setSelectedIds(ids);
    setIsUpdateFormVisible(true);
  };

  return (
    <>
      <EnhancedTable
        disableQsFilters
        data={computedList}
        count={count}
        isTableDataLoading={isTableDataLoading}
        selectIndex="id"
        tableName={t('other-costs.table_name')}
        headCells={headCells}
        filters={filters}
        onSync={onSync}
        onDelete={hasUserAccessToDeleteEntity ? handleDeletion : undefined}
        onUpdate={hasUserAccessToUpdateEntity ? handleUpdating : undefined}
        deleteModelName={ModelsToDelete.OtherCosts}
        createEntityBtnProps={
          hasUserAccessToCreateEntity
            ? {
                title: t('common.create'),
                onClick: () =>
                  setIsCreateFormVisible((prevState) => !prevState),
              }
            : undefined
        }
      />
      <>
        <CreateOtherCosts
          isOpen={isCreateFormVisible}
          onClose={() => setIsCreateFormVisible(false)}
          filterList={filterList.filter ?? {}}
          filterCount={filterCount.filter ?? {}}
        />
        <UpdateOtherCosts
          isOpen={isUpdateFormVisible}
          onClose={() => setIsUpdateFormVisible(false)}
          idsToUpdate={selectedIds}
          filterList={filterList.filter ?? {}}
          filterCount={filterCount.filter ?? {}}
        />
      </>
    </>
  );
};

/**
 * Wrapper to refresh component on flush store
 */
export default function OtherCostsListRefreshable() {
  return <OtherCostsList key={useSelector(getOtherCostsRefreshKey)} />;
}
