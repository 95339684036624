import React from 'react';
import { Sheet } from '@mui/joy';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { clone, compact, isEmpty } from 'lodash';
import { useLocation } from 'react-router-dom';

import {
  useShowNotificationOnProductionDataSyncStatusChange,
  useShowNotificationOnGenerateIncentiveReportStatusChangeChange,
} from 'src/modules/utils/hooks/notifications.hooks';
import { useMenuItems } from 'src/config';
import { getPageSettingPolicies } from 'src/modules/selectors/page-setting.selector';
import { getUserPolicies, getUserRoles } from 'src/modules/selectors/user';
import { AnyObject, IMenuItem } from 'src/modules/types';
import { useMainLayoutContext } from 'src/components/Layout/MainLayout/MainLayoutContext';
import { useSideBarMainMenuHelpers } from 'src/modules/utils/hooks/sideBar.hooks';
import { MainMenuItemLink } from './MainMenuItemLink';
import { MainMenuItem } from 'src/components/Layout/MainLayout/SideBar/SideBarMainMenu/MainMenuItem';
import { SideBarMenuItemSearch } from 'src/components/Layout/MainLayout/SideBar/SideBarMainMenu/SideBarMenuItemSearch';
import { SideBarMainMenuBanner } from 'src/components/Layout/MainLayout/SideBar/SideBarMainMenu/SideBarMainMenuBanner';
import { isLoggedIn } from 'src/modules/selectors/auth';
import { MainMenuItemLinkWithIcon } from 'src/components/Layout/MainLayout/SideBar/SideBarMainMenu/MainMenuItemLinkWithIcon';
import { useFavorites } from 'src/modules/utils/hooks/menu.hooks';

interface INestedListProps {
  items: IMenuItem[];
  hasAllItemsBeUncollapsed: boolean;
}

export const SideBarMainMenu = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const { sideBar, toggleSideBar } = useMainLayoutContext();

  const { filterBySearchTerm, filterMenuItems } = useSideBarMainMenuHelpers();

  const [searchTerm, setSearchTerm] = React.useState('');
  const [tabsTreeState, setTabsTreeState] = React.useState({});
  const [activeListItem, setActiveListItem] = React.useState<string | null>(
    null,
  );

  const menuItems = useMenuItems();
  const { favoriteMenuItems } = useFavorites();

  const isLogged = useSelector(isLoggedIn);
  const userRoles = useSelector(getUserRoles);
  const userPolicies = useSelector(getUserPolicies);
  const allPolicies = useSelector(getPageSettingPolicies);

  const filteredMenuItems = React.useMemo(() => {
    const items: IMenuItem[] = [];
    filterMenuItems(
      userRoles!,
      userPolicies!,
      clone(menuItems),
      items,
      allPolicies,
    );

    return filterBySearchTerm(searchTerm, items);
  }, [
    filterMenuItems,
    userRoles,
    userPolicies,
    menuItems,
    allPolicies,
    filterBySearchTerm,
    searchTerm,
  ]);

  const isGuestRole = isLogged && isEmpty(userRoles);

  const topLevelTabsNames = React.useMemo(() => {
    return menuItems.map((menuItem) => menuItem.name);
  }, [menuItems]);

  const handleClick = (name: string) => {
    if (sideBar.state === 'collapsed') {
      toggleSideBar();
    }

    const persistedStateItems = topLevelTabsNames.includes(name)
      ? {}
      : { ...tabsTreeState };

    setTabsTreeState({
      ...persistedStateItems,
      [name]: !(tabsTreeState as AnyObject)[name],
    });
  };

  const isURLMatched = (
    item: IMenuItem,
    pathname: string,
  ): boolean | undefined => {
    if (!item.url) {
      return false;
    }
    return (
      item.url === pathname ||
      item?.matchedUrls?.some((regexp) => pathname.match(regexp))
    );
  };

  const findActiveTree = (
    menuItems: IMenuItem[],
    pathname: string,
  ): AnyObject => {
    for (const item of compact(menuItems)) {
      if (item.subitems) {
        const result = findActiveTree(item.subitems, pathname);
        if (!isEmpty(result)) {
          return { ...result, [item.name]: true };
        }
      } else {
        if (isURLMatched(item, pathname)) {
          return { [item.name]: true };
        }
      }
    }
    return {};
  };

  const findActiveListItem = (
    menuItems: IMenuItem[],
    pathname: string,
  ): string => {
    for (const item of compact(menuItems)) {
      if (item.subitems) {
        const result = findActiveListItem(item.subitems, pathname);
        if (!isEmpty(result)) {
          return result;
        }
      } else {
        if (isURLMatched(item, pathname)) {
          return item.name;
        }
      }
    }
    return '';
  };

  React.useEffect(() => {
    setTabsTreeState({});

    const activeTreeNodes = findActiveTree(menuItems, pathname);
    const activeItem = findActiveListItem(menuItems, pathname);

    setTabsTreeState(activeTreeNodes);
    setActiveListItem(activeItem);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const drawMenu = (items: INestedListProps['items'], nesting = 1) => {
    return items.map((item) => {
      const key = item.name + item.url;

      if (item.subitems) {
        return (
          <MainMenuItem
            key={key}
            nesting={nesting}
            name={item.name}
            Icon={item.Icon}
            onClick={(name) => handleClick(name)}
            isActive={(tabsTreeState as AnyObject)[item.name]}
            isExpanded={
              sideBar.state === 'expanded' &&
              ((tabsTreeState as AnyObject)[item.name] || Boolean(searchTerm))
            }
          >
            {drawMenu(item.subitems, nesting + 1)}
          </MainMenuItem>
        );
      } else if (item.Icon) {
        return (
          <MainMenuItemLinkWithIcon
            key={key}
            nesting={nesting}
            external={item.external}
            isActive={activeListItem === item.name}
            withoutWrapper={true}
            to={item.url ?? ''}
            Icon={item.Icon}
            name={item.name}
          />
        );
      } else {
        return (
          <MainMenuItemLink
            key={key}
            nesting={nesting}
            external={item.external}
            isActive={activeListItem === item.name}
            withoutWrapper={true}
            to={item.url ?? ''}
            itemName={item.name}
          />
        );
      }
    });
  };

  useShowNotificationOnProductionDataSyncStatusChange();
  useShowNotificationOnGenerateIncentiveReportStatusChangeChange();

  return (
    <Sheet
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        backgroundColor: 'inherit',
        overflow: 'hidden',
      }}
    >
      <SideBarMenuItemSearch
        value={searchTerm}
        onSearchTermChange={(newValue) => setSearchTerm(newValue)}
      />

      <Sheet
        sx={{
          flexGrow: 1,
          overflowY: 'auto',
          backgroundColor: 'inherit',

          '&::-webkit-scrollbar': {
            backgroundColor: 'transparent',
          },
          overflowX: 'hidden',
        }}
      >
        {isGuestRole && <SideBarMainMenuBanner text={t('error.guest_role')} />}
        {!filteredMenuItems.length && (
          <SideBarMainMenuBanner
            text={t(
              'common.you_have_to_change_the_query_to_get_the_search_results_to_appear',
            )}
          />
        )}
        {favoriteMenuItems?.length && drawMenu(favoriteMenuItems)}
        {filteredMenuItems.length && drawMenu(filteredMenuItems)}
      </Sheet>
    </Sheet>
  );
};
