import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isEmpty, reduce } from 'lodash';

import {
  getGloballySelectedSites,
  getSitesByIds,
  getSitesComboboxListWithClient,
  getSitesWithIncentiveSettingsComboboxList,
} from 'src/modules/selectors/site';
import {
  IdsArray,
  IFilter,
  ISiteModel,
  IStoreState,
  PricingType,
} from 'src/modules/types';
import {
  getSiteComboboxListRequest,
  getSiteListRequest,
} from 'src/modules/actions/site';
import { defaultOrderDetails } from 'src/modules/utils/helpers/filter';
import Api from 'src/modules/utils/Api';
import { getSiteTaktExportingSettingsList } from 'src/modules/selectors/site-takt-exporting-settings.selector';
import { getSiteTaktExportingSettingsListRequest } from 'src/modules/actions';
import { useFilter } from 'src/modules/utils/hooks/useFilter';
import { ClientKey } from 'src/modules/constants';

export const useSiteRelationInclusion = () => {
  const globallySelectedSiteIds = useSelector(getGloballySelectedSites);

  return React.useMemo(
    () => ({
      relation: 'site',
      scope: {
        include: [{ relation: 'client', relationType: 'left' }],
        where: {
          id: {
            inq: globallySelectedSiteIds,
          },
        },
      },
    }),
    [globallySelectedSiteIds],
  );
};
export const sitesInclusions = [
  {
    relation: 'client',
  },
];

export const getSiteWithoutClientKeyComboboxFilter = (siteIds: number[]) => {
  const include = [
    ...sitesInclusions,
    {
      relation: 'importingSettings',
      relationType: 'left',
      scope: {
        where: {
          clientKey: null,
        },
      },
    },
  ];

  return {
    filter: {
      fields: {
        id: true,
        name: true,
        uuid: true,
      },
      where: {
        id: {
          inq: siteIds,
        },
      },
      include,
    },
  };
};

export const getSiteWithIncentiveSettingFilter = (siteIds: number[]) => {
  const include = [
    ...sitesInclusions,
    {
      relation: 'incentiveSettings',
      relationType: 'left',
      scope: {
        where: {
          id: {
            notnull: true,
          },
        },
      },
    },
  ];

  return {
    filter: {
      fields: {
        id: true,
        name: true,
        uuid: true,
      },
      where: {
        id: {
          inq: siteIds,
        },
      },
      include,
    },
  };
};

export const getSiteComboboxFilter = (
  siteIds: number[],
  clientKey?: ClientKey,
  excludeTestSites?: boolean,
) => {
  const include = clientKey
    ? [
        {
          relation: 'client',
          scope: {
            where: {
              clientKey,
            },
          },
        },
      ]
    : sitesInclusions;
  return {
    filter: {
      fields: {
        id: true,
        name: true,
        uuid: true,
      },
      where: {
        id: {
          inq: siteIds,
        },
        ...(excludeTestSites
          ? {
              name: {
                nlike: '%test%',
              },
            }
          : {}),
      },
      include,
      order: [`name ASC`],
    },
  };
};

export const useSiteComboboxFilter = (
  clientKey?: ClientKey,
  excludeTestSites?: boolean,
) => {
  const globallySelectedSiteIds = useSelector(getGloballySelectedSites);

  return React.useMemo(
    () =>
      getSiteComboboxFilter(
        globallySelectedSiteIds,
        clientKey,
        excludeTestSites,
      ),
    [globallySelectedSiteIds, clientKey, excludeTestSites],
  );
};

export const useTaktExportingSiteComboboxFilter = (clientKey?: ClientKey) => {
  const dispatcher = useDispatch();
  const globallySelectedSiteIds = useSelector(getGloballySelectedSites);

  const taktExportingSettingsFilter = React.useMemo(
    () => ({
      where: {
        siteId: { inq: globallySelectedSiteIds },
      },
    }),
    [globallySelectedSiteIds],
  );

  const { filterList } = useFilter(taktExportingSettingsFilter);

  const taktExportingSettingslist = useSelector(
    getSiteTaktExportingSettingsList,
    shallowEqual,
  );

  React.useEffect(() => {
    dispatcher(getSiteTaktExportingSettingsListRequest(filterList));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterList]);

  const siteIds = React.useMemo(
    () => taktExportingSettingslist.map((item) => item.siteId),
    [taktExportingSettingslist],
  );

  return React.useMemo(() => getSiteComboboxFilter(siteIds, clientKey), [
    siteIds,
    clientKey,
  ]);
};

export const useSiteWithoutClientKeyComboboxFilter = () => {
  const globallySelectedSiteIds = useSelector(getGloballySelectedSites);

  return React.useMemo(
    () => getSiteWithoutClientKeyComboboxFilter(globallySelectedSiteIds),
    [globallySelectedSiteIds],
  );
};
export const useSiteWithIncentiveSettingsComboboxFilter = () => {
  const globallySelectedSiteIds = useSelector(getGloballySelectedSites);

  return React.useMemo(
    () => getSiteWithIncentiveSettingFilter(globallySelectedSiteIds),
    [globallySelectedSiteIds],
  );
};

/**
 * A custom hook to fetch sites from store if they exist otherwise to make a request to fetch needed sites from
 * the server
 */
export const useFetchSitesByIds = (ids?: IdsArray) => {
  const dispatcher = useDispatch();
  // fetch sites list from store
  const sites = useSelector(
    (state) => getSitesByIds(state as IStoreState)(ids ?? []),
    shallowEqual,
  );
  return () => {
    if (isEmpty(sites)) {
      dispatcher(
        getSiteListRequest({
          filter: {
            ...(ids
              ? {
                  where: {
                    id: {
                      inq: ids,
                    },
                  },
                }
              : {}),
            include: sitesInclusions,
          },
        }),
      );
    }
  };
};

/**
 * A custom hook to fetch sites combobox list from store if they exist otherwise to make a request to fetch them from
 * the server
 */
export const useFetchSitesCombobox = (
  clientKey?: ClientKey,
  excludeTestSites?: boolean,
) => {
  const dispatch = useDispatch();
  const siteComboboxFilter = useSiteComboboxFilter(clientKey, excludeTestSites);

  return React.useCallback(() => {
    dispatch(getSiteComboboxListRequest(siteComboboxFilter));
  }, [dispatch, siteComboboxFilter]);
};

export const useFetchUsersSitesCombobox = () => {
  const siteComboboxFilter = useSiteComboboxFilter();

  const dispatcher = useDispatch();
  return () => {
    dispatcher(getSiteComboboxListRequest(siteComboboxFilter));
  };
};

export const useFetchTaktExportSitesCombobox = (clientKey?: ClientKey) => {
  const dispatch = useDispatch();
  const siteComboboxFilter = useTaktExportingSiteComboboxFilter(clientKey);
  // fetch combobox list from store
  const combobox = useSelector(getSitesComboboxListWithClient, shallowEqual);

  // Fetch data for sites comboboxes whenever globally selected sites have been changed
  React.useEffect(() => {
    dispatch(getSiteComboboxListRequest(siteComboboxFilter));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteComboboxFilter]);

  return () => {
    if (isEmpty(combobox)) {
      dispatch(getSiteComboboxListRequest(siteComboboxFilter));
    }
  };
};
/**
 * A custom hook to fetch sites combobox list from store if they exist otherwise to make a request to fetch them from
 * the server
 */
export const useFetchSitesWithoutClientKeyCombobox = () => {
  const dispatch = useDispatch();
  const siteComboboxFilter = useSiteWithoutClientKeyComboboxFilter();
  // fetch combobox list from store
  const combobox = useSelector(getSitesComboboxListWithClient, shallowEqual);

  // Fetch data for sites comboboxes whenever globally selected sites have been changed
  React.useEffect(() => {
    dispatch(getSiteComboboxListRequest(siteComboboxFilter));
  }, [siteComboboxFilter, dispatch]);

  return () => {
    if (isEmpty(combobox)) {
      dispatch(getSiteComboboxListRequest(siteComboboxFilter));
    }
  };
};

export const useFetchSitesWithIncentiveSettingsCombobox = () => {
  const dispatch = useDispatch();
  const siteComboboxFilter = useSiteWithIncentiveSettingsComboboxFilter();
  // fetch combobox list from store
  const combobox = useSelector(
    getSitesWithIncentiveSettingsComboboxList,
    shallowEqual,
  );

  // Fetch data for sites comboboxes whenever globally selected sites have been changed
  React.useEffect(() => {
    dispatch(getSiteComboboxListRequest(siteComboboxFilter));
  }, [siteComboboxFilter, dispatch]);

  return () => {
    if (isEmpty(combobox)) {
      dispatch(getSiteComboboxListRequest(siteComboboxFilter));
    }
  };
};

export const useSitesDefaultFilters = () => {
  const globallySelectedSiteIds = useSelector(getGloballySelectedSites);

  return React.useMemo(
    () => ({
      order: [`${defaultOrderDetails.orderBy} ${defaultOrderDetails.order}`],
      where: {
        id: {
          inq: globallySelectedSiteIds,
        },
        archived: { eq: false },
      },
      include: [
        {
          relation: 'client',
        },
      ],
    }),
    [globallySelectedSiteIds],
  );
};
export const useArchivedSitesDefaultFilters = () => {
  const sitesDefaultFilters = useSitesDefaultFilters();

  return React.useMemo(
    () => ({
      ...sitesDefaultFilters,
      where: {
        archived: { eq: true },
      },
      include: [
        {
          relation: 'client',
        },
      ],
    }),
    [sitesDefaultFilters],
  );
};

export const getPricingFilter = (siteIds: number[]): IFilter => ({
  filter: {
    fields: {
      id: true,
      pricingType: true,
    },
    where: {
      id: {
        inq: siteIds,
      },
    },
  },
});

export const getMappedPricingTypes = (pricingTypes: ISiteModel[]) =>
  reduce(
    pricingTypes,
    (acc, cur) => {
      acc[cur.id] = cur.pricingType;
      return acc;
    },
    {} as {
      [id: number]: PricingType;
    },
  );

export const useSitePricingTypesFilter = () => {
  const globallySelectedSiteIds = useSelector(getGloballySelectedSites);

  return React.useMemo(() => getPricingFilter(globallySelectedSiteIds), [
    globallySelectedSiteIds,
  ]);
};

export const useFetchSitesPricingTypes = () => {
  const sitePricingTypesFilter = useSitePricingTypesFilter();
  return async () => {
    const pricingTypes = await Api.Site.list(sitePricingTypesFilter);
    return getMappedPricingTypes(pricingTypes);
  };
};

export const useOvertimeBasisOptions = () => {
  const { t } = useTranslation();

  return React.useMemo(() => {
    return [
      { id: 'daily', name: t('common.daily') },
      { id: 'weekly', name: t('common.weekly') },
    ];
  }, [t]);
};
