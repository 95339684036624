import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dropdown,
  IconButton,
  ListDivider,
  Menu,
  MenuButton,
  MenuItem,
  Sheet,
  Typography,
} from '@mui/joy';
import Tooltip from '@mui/joy/Tooltip';
import { Link } from 'react-router-dom';
import { SessionCountdown } from '../../SessionCountdown';
import { AvatarLabelGroup } from '../../_ui-kit/AvatarLabelGroup';
import { getLoggedInUserData } from 'src/modules/selectors/user';
import { useTranslation } from 'react-i18next';
import { logoutRequest } from 'src/modules/actions';
import {
  LogOut01Svg,
  StarSolid,
  StarRegular,
  User01Svg,
} from 'src/components/svgIcons';
import { UserNotification } from 'src/components/UserNotifications';
import { useFavorites } from 'src/modules/utils/hooks/menu.hooks';

export const TopToolbarUserCommonData = () => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = React.useState(false);

  const { shortName, firstName, lastName, email } = useSelector(
    getLoggedInUserData,
  );

  const {
    showStar,
    addedToFavorites,
    addToFavorite,
    removeMutation,
  } = useFavorites();

  const dispatch = useDispatch();

  const handleUpdateFavorite = () => {
    if (addedToFavorites) {
      removeMutation(addedToFavorites!.id!);
    } else {
      addToFavorite();
    }
  };

  const title = `${firstName} ${lastName}`;

  const favoriteTooltip = React.useMemo(
    () =>
      addedToFavorites
        ? t('common.remove_from_favorites')
        : t('common.add_to_favorite'),
    [addedToFavorites, t],
  );

  const logout = () => {
    dispatch(logoutRequest());
    setIsOpen(false);
  };

  return (
    <Sheet
      sx={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'inherit',
      }}
    >
      <SessionCountdown />

      {showStar && (
        <Tooltip title={favoriteTooltip} variant="solid">
          <IconButton size="lg" onClick={handleUpdateFavorite}>
            {!addedToFavorites && <StarRegular width={20} height={20} />}
            {addedToFavorites && <StarSolid width={20} height={20} />}
          </IconButton>
        </Tooltip>
      )}

      <Sheet
        sx={{
          backgroundColor: 'inherit',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <UserNotification />

        <Dropdown open={isOpen} onOpenChange={() => setIsOpen((prev) => !prev)}>
          <MenuButton variant="plain">
            <AvatarLabelGroup text={email} title={title}>
              <Typography
                level="text_sm"
                fontWeight="semi_bold"
                textColor="gray.500"
              >
                {shortName}
              </Typography>
            </AvatarLabelGroup>
          </MenuButton>
          <Menu placement="top-start">
            <MenuItem
              component={Link}
              to="/profile"
              onClick={() => setIsOpen(false)}
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <User01Svg />
              <Typography
                level="text_sm"
                fontWeight="medium"
                textColor="colors.text.text_secondary.main"
              >
                {t('common.my_profile')}
              </Typography>
            </MenuItem>
            <ListDivider />
            <MenuItem
              onClick={logout}
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <LogOut01Svg />

              <Typography
                level="text_sm"
                fontWeight="medium"
                textColor="colors.text.text_secondary.main"
              >
                {t('common.logout')}
              </Typography>
            </MenuItem>
          </Menu>
        </Dropdown>
      </Sheet>
    </Sheet>
  );
};
