import { JSONSchemaType } from 'ajv';
import { ICreateEmployee } from '../../types';
// don't make this shorter since builder might face with circular dependencies
import { i18n } from '../../utils/i18n';

export const restoreEmployeeScheme: JSONSchemaType<
  Omit<
    ICreateEmployee,
    | 'isKronosAudited'
    | 'isYMSAudited'
    | 'isOtherAudited'
    | 'inactivatedAt'
    | 'file'
  > & { id: number; parentId?: number }
> = {
  type: 'object',
  required: [
    'id',
    'employeeId',
    'siteId',
    'positionId',
    'staffingId',
    'supervisorId',
    'firstName',
    'lastName',
    'payRate',
    'dateHired',
    'badge',
    'defaultShiftId',
    'defaultDepartmentId',
  ],
  additionalProperties: false,
  errorMessage: {
    required: i18n.t('error.validation.required'),
  },
  properties: {
    id: {
      type: 'number',
      isNotEmpty: true,
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    employeeId: {
      default: '',
      isNotEmpty: true,
      type: 'string',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    positionId: {
      default: NaN,
      isNotEmpty: true,
      type: 'number',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
        type: i18n.t('error.validation.number'),
      },
    },
    staffingId: {
      default: NaN,
      isNotEmpty: true,
      type: 'number',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
        type: i18n.t('error.validation.number'),
      },
    },
    supervisorId: {
      default: NaN,
      isNotEmpty: true,
      type: 'number',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
        type: i18n.t('error.validation.number'),
      },
    },
    siteId: {
      default: NaN,
      isNotEmpty: true,
      type: 'number',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
        type: i18n.t('error.validation.number'),
      },
    },
    defaultShiftId: {
      default: NaN,
      isNotEmpty: true,
      type: 'number',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
        type: i18n.t('error.validation.number'),
      },
    },
    defaultDepartmentId: {
      default: NaN,
      isNotEmpty: true,
      type: 'number',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
        type: i18n.t('error.validation.number'),
      },
    },
    firstName: {
      default: '',
      isNotEmpty: true,
      type: 'string',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    lastName: {
      default: '',
      isNotEmpty: true,
      type: 'string',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    payRate: {
      default: NaN,
      isNotEmpty: true,
      type: 'number',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
        type: i18n.t('error.validation.number'),
      },
    },
    dateHired: {
      default: null,
      isNotEmpty: true,
      type: 'string',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
        type: i18n.t('error.validation.required'),
      },
    },
    badge: {
      default: NaN,
      isNotEmpty: true,
      type: 'number',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
        type: i18n.t('error.validation.number'),
      },
    },
    separationdate: {
      nullable: true,
      type: 'string',
    },
    parentId: {
      nullable: true,
      type: 'number',
    },
    active: {
      nullable: true,
      type: 'boolean',
    },
    address: {
      nullable: true,
      type: 'string',
    },
    phonenumber: {
      nullable: true,
      type: 'string',
    },
    email: {
      nullable: true,
      type: 'string',
    },
    dateentered: {
      nullable: true,
      type: 'string',
    },
    last4SSN: {
      nullable: true,
      type: 'number',
    },
    wmsLogin: {
      nullable: true,
      type: 'string',
    },
    taktId: {
      nullable: true,
      type: 'string',
    },
    terminationReasonId: {
      nullable: true,
      type: 'number',
    },
    canRehire: {
      nullable: true,
      type: 'boolean',
    },
    isWmsLoginPrinted: {
      nullable: true,
      type: 'boolean',
    },
    wotc: {
      nullable: true,
      type: 'boolean',
    },
    emergencyContact: {
      nullable: true,
      type: 'string',
    },
    comments: {
      nullable: true,
      type: 'string',
    },
    hashSSN: {
      nullable: true,
      type: 'string',
    },
  },

  if: {
    anyOf: [
      {
        not: {
          required: ['terminationReasonId'],
        },
      },
      {
        properties: {
          terminationReasonId: {
            not: {
              const: null,
            },
          },
        },
      },
    ],
  },
  then: {
    required: ['separationdate'],
    properties: {
      separationdate: {
        isNotEmpty: true,
        type: 'string',
        errorMessage: {
          isNotEmpty: i18n.t('error.validation.required'),
          type: i18n.t('error.validation.required'),
        },
      },
    },
  },
  else: {
    if: {
      anyOf: [
        {
          not: {
            required: ['separationdate'],
          },
        },
        {
          properties: {
            separationdate: {
              not: {
                const: null,
              },
            },
          },
        },
      ],
    },
    then: {
      required: ['terminationReasonId'],
      properties: {
        terminationReasonId: {
          isNotEmpty: true,
          type: 'number',
          errorMessage: {
            isNotEmpty: i18n.t('error.validation.required'),
            type: i18n.t('error.validation.number'),
          },
        },
      },
    },
  },
};
