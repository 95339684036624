import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';

import { IMenuItem } from 'src/modules/types';
import { paths, useMenuItems } from 'src/config';
import { useBrowserHistoryFunctions } from 'src/modules/utils/hooks/browserHistory.hooks';
import Api from 'src/modules/utils/Api';
import { getQueryKey } from 'src/modules/utils/helpers/reactQuery.helpers';
import { StarSolidMainMenu } from 'src/components/svgIcons';

export const useMakeFlatMenuItems = () => {
  const menuItems = useMenuItems();
  const result: Array<Omit<IMenuItem, 'subitems'>> = [];

  function flattenHelper(arr: Array<IMenuItem>) {
    arr.forEach((item) => {
      result.push(item);
      if (item.subitems && Array.isArray(item.subitems)) {
        flattenHelper(item.subitems);
      }
    });
  }

  flattenHelper(menuItems);

  return result;
};

export const useFavorites = () => {
  const { t } = useTranslation();
  const flattenMenuItems = useMakeFlatMenuItems();
  const { getCurrentLocation } = useBrowserHistoryFunctions();

  const queryKey = getQueryKey(paths.FAVORITE);
  const queryClient = useQueryClient();

  const { data: favorites } = useQuery(queryKey, () => Api.Favorite.list(), {
    retry: false,
    cacheTime: 0,
  });

  const { mutate: addToFavoriteMutation } = useMutation(
    (url: string) => Api.Favorite.add(url),
    // invalidate data
    { onSuccess: () => queryClient.invalidateQueries(queryKey) },
  );

  const { mutate: removeMutation } = useMutation(
    (id: number) => Api.Favorite.delete(id),
    // invalidate data
    { onSuccess: () => queryClient.invalidateQueries(queryKey) },
  );

  const showStar = React.useMemo(() => {
    const route = getCurrentLocation();

    return !!flattenMenuItems.find((i) => i.url === route);
  }, [flattenMenuItems, getCurrentLocation]);

  const addedToFavorites = React.useMemo(() => {
    const route = getCurrentLocation();

    return favorites?.find((i) => i.url === route);
  }, [favorites, getCurrentLocation]);

  const favoriteMenuItems = React.useMemo(() => {
    const menuItems = favorites?.reduce<Array<Omit<IMenuItem, 'subitems'>>>(
      (acc, cur) => {
        const route = flattenMenuItems.find((i) => i.url === cur.url);
        if (route) {
          acc.push(route);
        }
        return acc;
      },
      [],
    );

    if (menuItems?.length) {
      return [
        {
          name: t('main_menu.favorites'),
          Icon: StarSolidMainMenu,
          subitems: menuItems,
        },
      ] as IMenuItem[];
    }

    return [];
  }, [favorites, flattenMenuItems, t]);

  const addToFavorite = () => {
    const route = getCurrentLocation();
    addToFavoriteMutation(route);
  };

  return {
    showStar,
    favorites,
    favoriteMenuItems,
    addToFavorite,
    removeMutation,
    addedToFavorites,
  };
};
